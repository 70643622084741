import { createContext, useContext, useReducer, ReactNode } from 'react'
import { IAddress, ICompanyInfo, IPersonalInfo, IStep, steps } from './signup/config'

interface ISignupState {
  steps: Array<IStep>
  currenStep: IStep
  allStepsComplete: boolean
  personalInfo: {
    userInfo: IPersonalInfo
    address: IAddress
  }
  companyInfo: {
    company: ICompanyInfo
    address: IAddress
  }
  password: string
}

const initalState: ISignupState = {
  steps: steps,
  currenStep: steps[0],
  allStepsComplete: false,
  personalInfo: {
    userInfo: { firstName: '', lastName: '', email: '', phone: '', profileImgUrl: '' },
    address: {
      addressOne: '',
      addressTwo: '',
      city: '',
      state: '',
      postalCode: '',
    },
  },
  companyInfo: {
    company: {
      companyName: '',
      companyEmail: '',
      companyPhone: '',
    },
    address: {
      addressOne: '',
      addressTwo: '',
      city: '',
      state: '',
      postalCode: '',
    },
  },
  password: '',
}

const SignUpContext = createContext<ISignupState | undefined>(undefined)

enum ESignupAction {
  SUBMIT_SIGN_UP = 'SUBMIT_SIGN_UP',
  UPDATE_NEXT_STEP = 'UPATE_NEXT_STEP',
  SET_ALL_FORMS_COMPLETE = 'SET_ALL_FORMS_COMPLETE',
  UPDATE_PERSONAL_INFO = 'UPDATE_PERSONAL_INFO',
  UPDATE_PERSONAL_INFO_ADDRESS = 'UPDATE_PERSONAL_INFO_ADDRESS',
  UPDATE_COMPANY_INFO = 'UPDATE_COMPANY_INFO',
  UPDATE_COMPANY_INFO_ADDRESS = 'UPDATE_COMPANY_INFO_ADDRESS',
  UPDATE_PASSWORD = 'UPDATE_PASSWORD',
  SET_STEP_COMPLETE = 'SET_STEP_COMPLETE',
}
// type ISubmitSignup = { type: ESignupAction.SUBMIT_SIGN_UP; payload: any }
type IUpdateNextStep = { type: ESignupAction.UPDATE_NEXT_STEP; payload: IStep }
type IUpdateStepComplete = { type: ESignupAction.SET_STEP_COMPLETE; payload: { current: IStep; steps: Array<IStep> } }
type ISetAllFormsComplete = { type: ESignupAction.SET_ALL_FORMS_COMPLETE; payload: boolean }
type IUpatePersonalInfo = { type: ESignupAction.UPDATE_PERSONAL_INFO; payload: IPersonalInfo }
type IUpatePersonalInfoAddress = { type: ESignupAction.UPDATE_PERSONAL_INFO_ADDRESS; payload: IAddress }
type IUpdateCompanyInfo = { type: ESignupAction.UPDATE_COMPANY_INFO; payload: ICompanyInfo }
type IUpdateCompanyInfoAdress = { type: ESignupAction.UPDATE_COMPANY_INFO_ADDRESS; payload: IAddress }
type IUpdatePassword = { type: ESignupAction.UPDATE_PASSWORD; payload: string }
type IAction = IUpdateNextStep | ISetAllFormsComplete | IUpatePersonalInfo | IUpatePersonalInfoAddress | IUpdateCompanyInfo | IUpdateCompanyInfoAdress | IUpdatePassword | IUpdateStepComplete
type IDispatch = (action: IAction) => void
const SignupDispatchContext = createContext<IDispatch | undefined>(undefined)

function signupReducer(state: ISignupState, action: IAction) {
  switch (action.type) {
    case ESignupAction.SET_ALL_FORMS_COMPLETE: {
      return {
        ...state,
        allStepsComplete: action.payload,
      }
    }
    case ESignupAction.UPDATE_NEXT_STEP: {
      return {
        ...state,
        currenStep: action.payload,
      }
    }
    case ESignupAction.SET_STEP_COMPLETE: {
      return {
        ...state,
        currenStep: action.payload.current,
        steps: action.payload.steps,
      }
    }
    case ESignupAction.UPDATE_PERSONAL_INFO: {
      return {
        ...state,
        personalInfo: {
          userInfo: action.payload,
          address: { ...state.personalInfo.address },
        },
      }
    }
    case ESignupAction.UPDATE_PERSONAL_INFO_ADDRESS: {
      return {
        ...state,
        personalInfo: {
          address: action.payload,
          userInfo: { ...state.personalInfo.userInfo },
        },
      }
    }
    case ESignupAction.UPDATE_COMPANY_INFO: {
      return {
        ...state,
        companyInfo: {
          company: action.payload,
          address: { ...state.companyInfo.address },
        },
      }
    }
    case ESignupAction.UPDATE_COMPANY_INFO_ADDRESS: {
      return {
        ...state,
        companyInfo: {
          address: action.payload,
          company: { ...state.companyInfo.company },
        },
      }
    }
    case ESignupAction.UPDATE_PASSWORD: {
      return {
        ...state,
        password: action.payload,
      }
    }
    default:
      return state
  }
}

export const SignupContextProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [state, dispatch] = useReducer(signupReducer, initalState)
  return (
    <SignUpContext.Provider value={state}>
      <SignupDispatchContext.Provider value={dispatch}>{children}</SignupDispatchContext.Provider>
    </SignUpContext.Provider>
  )
}

export function useSignupContextState() {
  const context = useContext(SignUpContext)
  if (context === undefined) {
    throw new Error('Context For Signup is Undefined')
  }
  return context
}

export function useSignupContextActions(): {
  updateStep: (payload: IStep) => void
  updateStepComplete: (payload: { current: IStep; steps: Array<IStep> }) => void
  updatePersonalInfo: (payload: IPersonalInfo) => void
  updatePersonalAddress: (payload: IAddress) => void
  updateCompanyInfo: (payload: ICompanyInfo) => void
  updateCompanyAddress: (payload: IAddress) => void
  updatePassword: (payload: string) => void
} {
  const dispatch = useContext(SignupDispatchContext)
  // const context = useContext(SignUpContext)
  if (dispatch === undefined) {
    throw new Error('Context For Signup is Undefined')
  }
  return {
    updateStep: (payload) => dispatch({ type: ESignupAction.UPDATE_NEXT_STEP, payload }),
    updateStepComplete: (payload) => dispatch({ type: ESignupAction.SET_STEP_COMPLETE, payload }),
    updatePersonalInfo: (payload) => dispatch({ type: ESignupAction.UPDATE_PERSONAL_INFO, payload }),
    updatePersonalAddress: (payload) => dispatch({ type: ESignupAction.UPDATE_PERSONAL_INFO_ADDRESS, payload }),
    updateCompanyInfo: (payload) => dispatch({ type: ESignupAction.UPDATE_COMPANY_INFO, payload }),
    updateCompanyAddress: (payload) => dispatch({ type: ESignupAction.UPDATE_COMPANY_INFO_ADDRESS, payload }),
    updatePassword: (payload) => dispatch({ type: ESignupAction.UPDATE_PASSWORD, payload }),
  }
}
