import { useState, useEffect } from 'react'
import { Client } from '../models/Client'
import { useAppSelector } from '../../../hooks/useRedux'
import { trackerService } from '../../../services'
import IProjectStatusReportRes from '../../../models/interfaces/project/IProjectStatusReportsRes'
import IUserRes from '../../../models/interfaces/user/IUserRes'
import ICreateClientReq from '../../../models/interfaces/clients/ICreateClientReq'

type ClientStore = {
  userInfo: IUserRes | null
  clients: Client[]
  setClients: (clients: Client[]) => void
  statusReport: IProjectStatusReportRes | null
  businessName: string
  setBusinessName: (arg: string) => void
  email: string
  setEmail: (arg: string) => void
  phone: string
  setPhone: (arg: string) => void
  billRate: number
  setBillRate: (arg: number) => void
  addressOne: string
  setAddressOne: (arg: string) => void
  addressTwo: string
  setAddressTwo: (arg: string) => void
  city: string
  setCity: (arg: string) => void
  state: string
  setState: (arg: string) => void
  postalCode: string
  setPostalCode: (arg: string) => void
  firstName: string
  setFirstName: (arg: string) => void
  lastName: string
  setLastName: (arg: string) => void
  contactEmail: string
  setContactEmail: (arg: string) => void
  contactPhone: string
  setContactPhone: (arg: string) => void
  showAddClientModal: boolean
  setShowAddClientModal: (arg: boolean) => void
  closeShowAddClientModal: () => void
  saveClient: () => void
}

export const useClientStore = (): ClientStore => {
  const { userInfo } = useAppSelector((state) => state.user)
  const [clients, setClients] = useState<Array<Client>>([])
  const [statusReport, setStatusReport] = useState<IProjectStatusReportRes | null>(null)
  const [businessName, setBusinessName] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [phone, setPhone] = useState<string>('')
  const [billRate, setBillRate] = useState<number>(0)
  const [addressOne, setAddressOne] = useState<string>('')
  const [addressTwo, setAddressTwo] = useState<string>('')
  const [city, setCity] = useState<string>('')
  const [state, setState] = useState<string>('')
  const [postalCode, setPostalCode] = useState<string>('')
  const [firstName, setFirstName] = useState<string>('')
  const [lastName, setLastName] = useState<string>('')
  const [contactEmail, setContactEmail] = useState<string>('')
  const [contactPhone, setContactPhone] = useState<string>('')
  const [showAddClientModal, setShowAddClientModal] = useState<boolean>(false)

  useEffect(() => {
    userInfo &&
      (async () => {
        const res = await trackerService.getClients(userInfo?._id ?? '')
        setClients(res.data)
        const statusRes = await trackerService.getProjectStatusCountsReport(userInfo?._id ?? '')
        setStatusReport(statusRes.data)
      })()
  }, [userInfo])

  const saveClient = async () => {
    if (!businessName || !phone || !billRate || !addressOne || !city || !state || !postalCode || !firstName || !lastName || !email || !contactEmail) {
      //toast.error('Required field missing')
      return
    }
    const createClientReq: ICreateClientReq = {
      businessName,
      phone,
      email,
      billRate,
      address: {
        addressOne,
        addressTwo,
        city,
        postalCode,
        state,
      },
      contact: {
        firstName,
        lastName,
        email: contactEmail,
        phone: contactPhone,
      },
      notes: '',
      userId: userInfo?._id ?? '',
    }
    const updateRes = await trackerService.saveClient(createClientReq)

    if (updateRes.success) {
      // toast.success('New Client Saved.')
      clearInputs()
      setShowAddClientModal(false)
      const res = await trackerService.getClients(userInfo?._id ?? '')
      setClients(res.data)
    } else if (!updateRes.success) {
      // toast.error('Could Not Save Client.')
    }
  }

  const closeShowAddClientModal = () => {
    setShowAddClientModal(false)
    clearInputs()
  }

  const clearInputs = () => {
    setBusinessName('')
    setEmail('')
    setPhone('')
    setBillRate(0)
    setAddressOne('')
    setAddressTwo('')
    setCity('')
    setState('')
    setPostalCode('')
    setFirstName('')
    setLastName('')
    setContactEmail('')
    setContactPhone('')
  }

  return {
    clients: clients,
    statusReport: statusReport,
    userInfo: userInfo,
    setClients: (clients: Client[]) => setClients(clients),
    businessName: businessName,
    setBusinessName: (arg: string) => setBusinessName(arg),
    email: email,
    setEmail: (arg: string) => setEmail(arg),
    phone: phone,
    setPhone: (arg: string) => setPhone(arg),
    billRate: billRate,
    setBillRate: (arg: number) => setBillRate(arg),
    addressOne: addressOne,
    setAddressOne: (arg: string) => setAddressOne(arg),
    addressTwo: addressTwo,
    setAddressTwo: (arg: string) => setAddressTwo(arg),
    city: city,
    setCity: (arg: string) => setCity(arg),
    state: state,
    setState: (arg: string) => setState(arg),
    postalCode: postalCode,
    setPostalCode: (arg: string) => setPostalCode(arg),
    firstName: firstName,
    setFirstName: (arg: string) => setFirstName(arg),
    lastName: lastName,
    setLastName: (arg: string) => setLastName(arg),
    contactEmail: contactEmail,
    setContactEmail: (arg: string) => setContactEmail(arg),
    contactPhone: contactPhone,
    setContactPhone: (arg: string) => setContactPhone(arg),
    showAddClientModal: showAddClientModal,
    setShowAddClientModal: (arg: boolean) => setShowAddClientModal(arg),
    saveClient: () => saveClient(),
    closeShowAddClientModal: () => closeShowAddClientModal(),
  }
}
