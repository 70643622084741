export interface IClientProperites {
  _id: string
  userId: string
  businessName: string
  email: string
  phone: string
  billRate: number
  notes: string
  contact: any
  address: any
}

export class Client {
  constructor(public readonly _id: string, public readonly userId: string, public readonly businessName: string, public readonly email: string, public readonly phone: string, public readonly billRate: number, public readonly notes: string, public readonly contact: any, public readonly address: any) {}

  static fromProperites(properties: IClientProperites) {
    const { _id, userId, businessName, email, phone, billRate, notes, contact, address } = properties
    return new Client(_id, userId, businessName, email, phone, billRate, notes, contact, address)
  }

  get properites(): IClientProperites {
    return {
      _id: this._id,
      userId: this.userId,
      businessName: this.businessName,
      email: this.email,
      phone: this.phone,
      billRate: this.billRate,
      notes: this.notes,
      contact: this.contact,
      address: this.address,
    }
  }

  get id(): string {
    return this._id
  }
}
