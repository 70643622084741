import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../../store'
import IClient from '../../../models/interfaces/clients/IClient'

// Define a type for the slice state
interface UserState {
  client: IClient | null
}

// Define the initial state using that type
const initialState: UserState = {
  client: null,
}

export const clientSlice = createSlice({
  name: 'client',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setClient: (state, action: PayloadAction<any>) => {
      state.client = action.payload
    },
    resetClientState: (state) => {
      return initialState
    },
  },
})

export const { setClient, resetClientState } = clientSlice.actions

// Other code such as selectors can use the imported `RootState` type
// export const selectCount = (state: RootState) => state.counter.value

export default clientSlice.reducer
