export enum EStorageKeys {
  ACCESS_TOKEN = 'accessToken',
  USER_ID = 'userId',
}
/**
 * Set a value to localstorage
 * @param storageKey EStorageKeys
 */
export const getStorageValue = (storageKey: EStorageKeys) => {
  const value = localStorage.getItem(storageKey)
  if (value) {
    return value
  }
}
/**
 *
 * @param storageKey EStorageKeys
 * @param data : string
 */
export const setStorageValue = (storageKey: EStorageKeys, data: string | Array<any> | Object) => {
  if (!data) console.error('Data was not provided as a value')
  let convertedValue = null
  if (typeof data === 'string') {
    convertedValue = data
  } else if (typeof data === 'object' || Array.isArray(data)) {
    convertedValue = JSON.stringify(data)
  }
  convertedValue && localStorage.setItem(storageKey, convertedValue)
}
/**
 *
 * @param storageKey EStorageKeys
 */
export const removeStorageValue = (storageKey: EStorageKeys) => {
  localStorage.removeItem(storageKey)
}
/**
 *  Clears all KVPs from localstorage
 */
export const clearStorageValues = () => {
  localStorage.clear()
}
