import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useSignupContextState, useSignupContextActions } from '../useSignupContext'
import { IAddress } from './config'
import * as Yup from 'yup'
import { useFormik } from 'formik'

import CustomFormInput from './components/CustomFormInput'
import StepLayoutWrapper from './components/StepLayoutWrapper'

const CompnayAddress = () => {
  const { companyInfo, currenStep, steps } = useSignupContextState()
  const { updateCompanyAddress, updateStepComplete } = useSignupContextActions()

  const initialValues: IAddress = companyInfo.address ? companyInfo.address : { addressOne: '', addressTwo: '', city: '', state: '', postalCode: '' }
  const validationSchema = Yup.object({ addressOne: Yup.string().required('Required'), addressTwo: Yup.string(), city: Yup.string().required('Required'), state: Yup.string().min(2).max(2, 'Two character city codes').required('Required'), postalCode: Yup.string().min(5, 'Five digits only').max(5).required('Required') })

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      console.log(values)
    },
  })

  useEffect(() => {
    handleFormValid(formik.dirty, formik.isValid, formik.values)
  }, [formik.values, formik.isValid])

  const handleFormValid = (dirty: boolean, isValid: boolean, values: IAddress) => {
    if ((dirty || !Object.values(values).includes('')) && isValid) {
      updateCompanyAddress(values)
      updateGlobalStepData(true)
    } else if (!isValid) {
      updateGlobalStepData(false)
    }
  }

  const updateGlobalStepData = (isComplete: boolean) => {
    const updatedSteps = steps.map((step) => {
      if (step.id === currenStep.id) {
        return { ...currenStep, stepComplete: isComplete }
      } else {
        return step
      }
    })
    const step = { ...currenStep, stepComplete: isComplete }
    updateStepComplete({ current: step, steps: updatedSteps })
  }
  return (
    <StepLayoutWrapper title={currenStep.title}>
      <FormWrapper>
        <div className='flow-inputs-wrapper'>
          <CustomFormInput name={'addressOne'} value={formik.values.addressOne} handleChange={formik.handleChange} error={formik.touched.addressOne && formik.errors.addressOne ? formik.errors.addressOne : ''} label='Steet One' onBlur={formik.handleBlur} />
          <CustomFormInput name={'addressTwo'} value={formik.values.addressTwo} handleChange={formik.handleChange} error={formik.touched.addressTwo && formik.errors.addressTwo ? formik.errors.addressTwo : ''} label='Street Two' onBlur={formik.handleBlur} />
        </div>
        <div className='flow-inputs-wrapper'>
          <CustomFormInput name={'city'} value={formik.values.city} handleChange={formik.handleChange} error={formik.touched.city && formik.errors.city ? formik.errors.city : ''} label='City' onBlur={formik.handleBlur} />
          <CustomFormInput name={'state'} value={formik.values.state} handleChange={formik.handleChange} error={formik.touched.state && formik.errors.state ? formik.errors.state : ''} label='State' onBlur={formik.handleBlur} />
          <CustomFormInput name={'postalCode'} value={formik.values.postalCode} handleChange={formik.handleChange} error={formik.touched.postalCode && formik.errors.postalCode ? formik.errors.postalCode : ''} label='Postal Code' onBlur={formik.handleBlur} />
        </div>
      </FormWrapper>
    </StepLayoutWrapper>
  )
}

export default CompnayAddress

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  .flow-inputs-wrapper {
    display: flex;
    justify-content: space-around;
    margin: 15px 0;
  }
`
