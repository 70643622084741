import React, { useState } from 'react'
import styled from 'styled-components'
import { useAppDispatch, useAppSelector } from '../../hooks/useRedux'
import { status as statusData } from '../../common/data/Status'
import { trackerService } from '../../services'
import toast from 'react-hot-toast'

import Typography from '@mui/material/Typography'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Chip from '@mui/material/Chip'
import AddIcon from '@mui/icons-material/Add'
import Button from '@mui/material/Button'
import Paper from '@mui/material/Paper'
import AppModal from '../global/AppModal'
import ISaveTaskReq from '../../models/interfaces/project/ISaveTaskReq'
import { updateProjectInfo } from '../../store/features/project/projectSlice'
import { determineChipColor } from '../../common/globalHelperFunctions'
import ITask from '../../models/interfaces/project/ITask'
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip'
import InfoIcon from '@mui/icons-material/Info'

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => <Tooltip {...props} classes={{ popper: className }} />)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#505050',
    color: '#fff',
    fontSize: 11,
  },
}))

interface IProjectTableProps {
  clientId: string
}

const ProjectDetailTable: React.FC<IProjectTableProps> = ({ clientId }) => {
  const dispatch = useAppDispatch()
  const { project } = useAppSelector((state) => state.project)
  const { userInfo } = useAppSelector((state) => state.user)
  const [showAddTaskModal, setShowAddTaskModal] = useState<boolean>(false)
  const [showEditTaskModal, setShowEditTaskModal] = useState<boolean>(false)
  const [taskId, setTaskId] = useState<string>('') // Only Used for Editing Purposes
  const [title, setTitle] = useState<string>('')
  const [description, setDescription] = useState<string>('')
  const [status, setStatus] = useState<string>('')
  const [taskHours, setTaskHours] = useState<number>(0)
  const [errorMsg, setErrorMsg] = useState<string | null>(null)

  const saveTask = async () => {
    setErrorMsg(null)
    if (!userInfo?._id || !clientId) {
      return
    }

    if (!status || !title || !description) {
      setErrorMsg('Could not save a *Required filed is was not completed.')
      return
    }

    const reqPayload: ISaveTaskReq = {
      userId: userInfo?._id,
      clientId: clientId,
      tasks: [
        {
          status: status,
          title: title,
          description: description,
          taskHours: taskHours,
        },
        ...(project?.tasks ?? []),
      ],
    }
    const res = await trackerService.saveTask(project?._id ?? '', reqPayload)
    if (res.success) {
      toast.success('Saved New Task Successful.')
      resetFeilds()
      setShowAddTaskModal(false)
      const res = await trackerService.getProject(project?._id ?? '')
      dispatch(updateProjectInfo(res.data))
    } else if (!res.success) {
      toast.error('Could Not Save New Task.')
    }
  }

  const closeShowAddTaskModal = () => {
    setShowAddTaskModal(false)
    resetFeilds()
  }

  const handleEditTask = (taskId: string) => {
    setTaskId(taskId)
    const taskToEdit = project?.tasks.find((task) => task._id === taskId)
    if (taskToEdit) {
      setTitle(taskToEdit.title)
      setDescription(taskToEdit.description)
      setStatus(taskToEdit.status)
      setTaskHours(taskToEdit.taskHours)
      setShowEditTaskModal(true)
    }
  }

  const closeShowEditTaskModal = () => {
    setShowEditTaskModal(false)
    resetFeilds()
  }

  const handleSaveEditTask = async () => {
    let tasksCopy: Array<ITask> = []

    if (project?.tasks) {
      tasksCopy = project?.tasks.map((task) => {
        return Object.assign({}, { ...task })
      })
    }

    tasksCopy.forEach((task) => {
      if (task._id === taskId) {
        task.description = description
        task.title = title
        task.taskHours = taskHours
        task.status = status
      }
    })

    setErrorMsg(null)
    if (!userInfo?._id || !clientId) {
      return
    }

    if (!status || !title || !description) {
      setErrorMsg('Could not save a *Required filed is was not completed.')
      return
    }

    const reqPayload: ISaveTaskReq = {
      userId: userInfo?._id,
      clientId: clientId,
      tasks: [...tasksCopy],
    }
    const res = await trackerService.saveTask(project?._id ?? '', reqPayload)
    if (res.success) {
      toast.success('Task Update Successful.')
      resetFeilds()
      setShowEditTaskModal(false)
      const res = await trackerService.getProject(project?._id ?? '')
      dispatch(updateProjectInfo(res.data))
    } else if (!res.success) {
      toast.error('Could Not Update Task.')
    }
  }

  const resetFeilds = () => {
    setTitle('')
    setDescription('')
    setStatus('')
    setTaskHours(0)
    setTaskId('')
  }

  return (
    <TableContainer component={Paper} elevation={6} style={{ borderRadius: '18px', marginTop: '3%' }}>
      <div style={{ padding: '2%', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', borderBottom: '2px solid rgba(0,0,0,0.1)', backgroundColor: 'var(--app-theme-color-secondary)' }}>
        <div style={{ width: '90%' }}>
          <Typography variant='h5'>Tasks List</Typography>
        </div>
        <div style={{ width: '8%' }}>
          <Button
            variant='outlined'
            style={{ width: '35px', height: '35px' }}
            title='Add New Project'
            onClick={() => {
              setShowAddTaskModal(true)
            }}
          >
            <AddIcon style={{ width: '30px' }} />
          </Button>
        </div>
      </div>
      <Table sx={{ minWidth: 650 }} aria-label='simple table'>
        <TableHead>
          <TableRow>
            <TableCell>Title</TableCell>
            <TableCell>Description</TableCell>
            <TableCell align='right'>
              Status{' '}
              <CustomTooltip title='Only "Complete" status Tasks will be factored in when generating Reports or Invoices.' arrow>
                <InfoIcon style={{ color: '#f44336' }} />
              </CustomTooltip>
            </TableCell>
            <TableCell align='right'>Hours</TableCell>
            <TableCell align='right'>Edit</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {project?.tasks && project.tasks.length > 0 ? (
            project.tasks.map((task) => (
              <TableRow key={project.title} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component='th' scope='row'>
                  {task.title}
                </TableCell>
                <TableCell>{task.description}</TableCell>
                <TableCell align='right'>
                  <Chip label={task.status} style={{ backgroundColor: determineChipColor(task.status), minWidth: '50px' }} />
                </TableCell>
                <TableCell align='right'>{task.taskHours}</TableCell>
                <TableCell align='right'>
                  <Button
                    variant='outlined'
                    size='small'
                    onClick={() => {
                      handleEditTask(task?._id || '')
                    }}
                  >
                    Edit
                  </Button>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableCell>
              <Typography variant='h5'>No Data</Typography>
            </TableCell>
          )}
        </TableBody>
      </Table>
      <AppModal show={showAddTaskModal} closeBtnAction={closeShowAddTaskModal} saveBtnAction={saveTask}>
        <AddTaskWrapper>
          <h2 className='form-title'>Add Task</h2>
          <form className='task-form'>
            <select
              className='app-form-input'
              onChange={(e) => {
                setStatus(e.target.value)
              }}
            >
              <option selected disabled>
                *Select Status
              </option>
              {statusData.map((_status, index: number) => {
                return (
                  <option key={index} value={_status}>
                    {_status}
                  </option>
                )
              })}
            </select>
            <input
              type='text'
              className='app-form-input'
              placeholder='*Title'
              value={title}
              onChange={(e) => {
                setTitle(e.target.value)
              }}
            />
            <input
              type='text'
              className='app-form-input'
              placeholder='*Description'
              value={description}
              onChange={(e) => {
                setDescription(e.target.value)
              }}
            />
            <input
              className='app-form-input'
              placeholder='*Task Hours'
              value={taskHours.toString()}
              type='number'
              min={0}
              onChange={(e) => {
                setTaskHours(Number(e.target.value))
              }}
            />
            {errorMsg && <p className='error-txt'>{errorMsg}</p>}
          </form>
        </AddTaskWrapper>
      </AppModal>
      {/* Start Edit Task UI */}
      <AppModal show={showEditTaskModal} closeBtnAction={closeShowEditTaskModal} saveBtnAction={handleSaveEditTask}>
        <AddTaskWrapper>
          <h2 className='form-title'>Edit Task</h2>
          <form className='task-form'>
            <select
              className='app-form-input'
              value={status}
              onChange={(e) => {
                setStatus(e.target.value)
              }}
            >
              <option selected disabled>
                *Select Status
              </option>
              {statusData.map((_status, index: number) => {
                return (
                  <option key={index} value={_status}>
                    {_status}
                  </option>
                )
              })}
            </select>
            <input
              type='text'
              className='app-form-input'
              placeholder='*Title'
              value={title}
              onChange={(e) => {
                setTitle(e.target.value)
              }}
            />
            <input
              type='text'
              className='app-form-input'
              placeholder='*Description'
              value={description}
              onChange={(e) => {
                setDescription(e.target.value)
              }}
            />
            <input
              className='app-form-input'
              placeholder='*Task Hours'
              value={taskHours.toString()}
              type='number'
              min={0}
              onChange={(e) => {
                setTaskHours(Number(e.target.value))
              }}
            />
            {errorMsg && <p className='error-txt'>{errorMsg}</p>}
          </form>
        </AddTaskWrapper>
      </AppModal>
    </TableContainer>
  )
}

export default ProjectDetailTable

const AddTaskWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  .form-title {
    font-size: 45px;
    background-color: var(--app-theme-color-secondary);
    width: 100%;
    height: 100px;
    margin-top: 0;
    border-radius: 18px 18px 0 0;
    text-align: center;
  }

  .task-form {
    display: flex;
    flex-direction: column;
    padding: 2%;
    min-height: 250px;
  }

  .error-txt {
    color: #d32f2f;
    font-size: 14px;
    text-align: center;
  }
`
