import { createBrowserRouter } from 'react-router-dom'
import App from '../App'
import Clients from '../domains/client'
import ClientDetail from '../domains/client/ClientDetail'
import ProjectDetail from '../domains/project/ProjectDetail'
import Invoices from '../domains/invoices/Invoices'
import InvoiceDetail from '../domains/invoices/InvoiceDetail'
import Income from '../domains/income/Income'
import Signup from '../domains/common/signup/signup'
import Login from '../domains/common/Login'
import Settings from '../domains/settings/Settings'
import { PrivateRoute } from '../components/auth/PrivateRoute'
import Account from '../domains/settings/account'
import Password from '../domains/common/signup/signup/Password'
import PersonalInfo from '../domains/common/signup/signup/PersonalInfo'
import CompanyInfo from '../domains/common/signup/signup/CompanyInfo'
import PersonalAddress from '../domains/common/signup/signup/PersonalAddress'
import CompanyAddress from '../domains/common/signup/signup/CompanyAddress'

export const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    children: [
      {
        path: 'login',
        element: <Login />,
      },
      {
        path: 'signup',
        element: <Signup />,
        children: [
          {
            path: 'personal-info',
            element: <PersonalInfo />,
          },
          {
            path: 'personal-address',
            element: <PersonalAddress />,
          },
          {
            path: 'company-info',
            element: <CompanyInfo />,
          },
          {
            path: 'company-address',
            element: <CompanyAddress />,
          },
          {
            path: 'password',
            element: <Password />,
          },
        ],
      },
      {
        path: 'clients',
        element: (
          <PrivateRoute>
            <Clients />
          </PrivateRoute>
        ),
      },
      {
        path: 'client/:clientId',
        element: (
          <PrivateRoute>
            <ClientDetail />
          </PrivateRoute>
        ),
      },
      {
        path: 'client/:clientId/project/:projectId',
        element: (
          <PrivateRoute>
            <ProjectDetail />
          </PrivateRoute>
        ),
      },
      {
        path: 'invoices',
        element: (
          <PrivateRoute>
            <Invoices />
          </PrivateRoute>
        ),
      },
      {
        path: 'invoice/:invoiceId',
        element: (
          <PrivateRoute>
            <InvoiceDetail />
          </PrivateRoute>
        ),
      },
      {
        path: 'income',
        element: (
          <PrivateRoute>
            <Income />
          </PrivateRoute>
        ),
      },
      {
        path: 'settings',
        element: (
          <PrivateRoute>
            <Settings />
          </PrivateRoute>
        ),
        children: [
          {
            path: 'account',
            element: (
              <PrivateRoute>
                <Account />
              </PrivateRoute>
            ),
          },
          {
            path: 'password',
            element: (
              <PrivateRoute>
                <Password />
              </PrivateRoute>
            ),
          },
        ],
      },
    ],
  },
])
