export const steps: Array<IStep> = [
  {
    id: 1,
    title: 'Personal Info',
    linkPath: 'personal-info',
    stepComplete: false,
  },
  {
    id: 2,
    title: 'Personal Address',
    linkPath: 'personal-address',
    stepComplete: false,
  },
  {
    id: 3,
    title: 'Company Info',
    linkPath: 'company-info',
    stepComplete: false,
  },
  {
    id: 4,
    title: 'Company Address',
    linkPath: 'company-address',
    stepComplete: false,
  },
  {
    id: 5,
    title: 'Password',
    linkPath: 'password',
    stepComplete: false,
  },
]

export interface IPersonalInfo {
  firstName: string
  lastName: string
  email: string
  phone: string
  profileImgUrl?: string
}

export interface IAddress {
  addressOne: string
  addressTwo: string
  city: string
  state: string
  postalCode: string
}

export interface ICompanyInfo {
  companyName: string
  companyEmail: string
  companyPhone: string
}

export interface IStep {
  id: number
  title: string
  linkPath: string
  stepComplete: boolean
}
