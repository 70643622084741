import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'
import { useAppSelector } from '../../hooks/useRedux'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Chip from '@mui/material/Chip'
import AddIcon from '@mui/icons-material/Add'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Paper from '@mui/material/Paper'
import IProject from '../../models/interfaces/project/IProject'
import { trackerService } from '../../services'
import AppModal from '../global/AppModal'
import ISaveProjectReq from '../../models/interfaces/project/ISaveProjectReq'
import { status as statusData } from '../../common/data/Status'
import { determineChipColor, displaySysTimeToReadable } from '../../common/globalHelperFunctions'
import { EStatus } from '../../models/Enums/EStatus'
import PDFInvoice from '../global/pdf/PDFInvoice'
import IInvoicePDF from '../../models/interfaces/pdfs/IInvoicePDF'
import { EInvoiceTerms } from '../../models/Enums/EInvoiceTerms'

import FilterAltIcon from '@mui/icons-material/FilterAlt'
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff'

import CircularProgress from '@mui/material/CircularProgress'

const enum EProjectFilters {
  Status = 'Status',
  Billed = 'Billed',
  Paid = 'Paid',
}

interface IProjectTableProps {
  clientId: string | null
}

const ProjectTable: React.FC<IProjectTableProps> = ({ clientId }) => {
  const navigate = useNavigate()
  const { userInfo } = useAppSelector((state) => state.user)
  const { client } = useAppSelector((state) => state.clients)
  const [projects, setProjects] = useState<Array<IProject> | null>(null)
  const [showAddModal, setShowAddModal] = useState<boolean>(false)
  const [title, setitle] = useState<string>('')
  const [description, setDescription] = useState<string>('')
  const [status, setStatus] = useState<string>('')
  const [notes, setNotes] = useState<string>('')
  const [errorMsg, setErrorMsg] = useState<string | null>(null)

  const [invoiceData, setInvoiceData] = useState<IInvoicePDF | null>(null)
  const [showInvoiceModal, setShowInvoiceModal] = useState<boolean>(false)
  const [invoiceList, setInvoiceList] = useState<Array<string>>([])

  const [filters, setFilters] = useState<{ userId?: string; status?: EStatus | string | null; isBilled?: boolean | null; isPaid?: boolean | null }>({})
  const [showStatusFilter, setShowStatusFilter] = useState<boolean>(false)
  const [showBilledFilter, setShowBilledFilter] = useState<boolean>(false)
  const [showPaidFilter, setShowPaidFilter] = useState<boolean>(false)

  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    clientId &&
      (async () => {
        setLoading(true)
        const res = await trackerService.getClientProjects(clientId)
        setLoading(false)
        setProjects(res.data)
      })()
  }, [])

  const saveProject = async () => {
    setErrorMsg(null)
    if (!userInfo?._id || !clientId) {
      return
    }

    if (!status || !title || !description) {
      setErrorMsg('Could not save a *Required filed is was not completed.')
      return
    }

    const reqPayload: ISaveProjectReq = {
      userId: userInfo?._id,
      clientId: clientId,
      status: status,
      title: title,
      description: description,
      notes: notes,
    }
    const res = await trackerService.saveProject(reqPayload)
    if (res.success) {
      toast.success('Project Save Successful')
      setitle('')
      setDescription('')
      setStatus('')
      setShowAddModal(false)
      const res = await trackerService.getClientProjects(clientId || '')
      setProjects(res.data)
    } else if (!res.success) {
      toast.error('Could Not Save Project.')
    }
  }

  const closeAddProjectModal = () => {
    setShowAddModal(false)
    setErrorMsg(null)
  }

  const calculateBillableHours = (project: IProject) => {
    const billableHrs = project.tasks.reduce((accum: any, curr) => {
      if (curr.status === EStatus.Complete) {
        return (accum += curr.taskHours)
      } else {
        return (accum += 0)
      }
    }, 0)
    return billableHrs
  }

  const handleUpdateInvoiceList = (projectId: string) => {
    const tempList = [...invoiceList]
    const possibleIndexOfFound = tempList.indexOf(projectId)
    if (possibleIndexOfFound !== -1) {
      tempList.splice(possibleIndexOfFound, 1)
    } else {
      tempList.push(projectId)
    }
    setInvoiceList(tempList)
  }

  const handleCreateInvoice = async () => {
    const selectedProjects: Array<IProject> = []
    invoiceList.forEach((projectId) => {
      projects?.forEach((project) => {
        if (project._id === projectId) {
          selectedProjects.push(project)
        }
      })
    })

    const selectedProjectsList: Array<{ title: string; description: string; hours: number; charge: number }> = []
    if (selectedProjects.length > 0) {
      selectedProjects.forEach((project) => {
        const projectAdapter: { title: string; description: string; hours: number; charge: number } = {
          title: project.title,
          description: project.description,
          hours: 0,
          charge: 0,
        }
        project.tasks.forEach((task) => {
          if (task.status === EStatus.Complete) {
            projectAdapter.hours += task.taskHours
            projectAdapter.charge = projectAdapter.charge + task.taskHours * (client?.billRate ?? 0)
          }
        })
        selectedProjectsList.push(projectAdapter)
      })
    }

    const invoiceTotalCharge = selectedProjectsList.reduce((prev, accum) => {
      return (prev += accum.charge)
    }, 0)
    const invoiceTotalhours = selectedProjectsList.reduce((prev, accum) => {
      return (prev += accum.hours)
    }, 0)
    try {
      const currInvNumber = await trackerService.getCurrentInvoiceNumber(userInfo?._id ?? '')
      if (!currInvNumber.success) {
        return
      }
      const invoiceNumber = currInvNumber.data.invoiceNumber + 1
      const invoiceDate = new Date(Date.now())
      const invoicePropsDate: IInvoicePDF = {
        invoiceNumber: invoiceNumber,
        invoiceDate: invoiceDate,
        totalHoursBilled: invoiceTotalhours,
        invoiceTotal: invoiceTotalCharge,
        clientId: client?._id ?? '',
        userId: userInfo?._id ?? '',
        projectListItems: selectedProjectsList,
        projectIds: invoiceList,
        terms: EInvoiceTerms.DUE_UPON_RECIEPT,
        isPaid: false,
        userInformation: {
          businessName: userInfo?.firstName + ' ' + userInfo?.lastName ?? '',
          streetOne: userInfo?.address.addressOne ?? '',
          streetTwo: userInfo?.address.addressTwo ?? '',
          city: userInfo?.address.city ?? '',
          state: userInfo?.address.state ?? '',
          postalCode: userInfo?.address.postalCode ?? '',
          phone: userInfo?.phone ?? '',
          email: userInfo?.email ?? '',
        },
        clientInformation: {
          businessName: client?.businessName ?? '',
          streetOne: client?.address.addressOne ?? '',
          streetTwo: client?.address.addressTwo ?? '',
          city: client?.address.city ?? '',
          state: client?.address.state ?? '',
          postalCode: client?.address.postalCode ?? '',
          billRate: client?.billRate ?? 0,
        },
      }
      setInvoiceData(invoicePropsDate)
      setShowInvoiceModal(true)
    } catch (err: any) {
      console.error(err.message)
    }
  }

  const handleSaveInvoice = async () => {
    // ! those project status isPaid to true IF/WHEN the client reconciles(pays) the invoice.
    if (!invoiceData) {
      return
    }
    try {
      const response = await trackerService.createInvoice(invoiceData)
      console.log(response)
      // implement toasts
      if (response.success) {
        setInvoiceData(null)
        setInvoiceList([])
        const res = await trackerService.getClientProjects(clientId ?? '')
        setProjects(res.data)
        setShowInvoiceModal(false)
      }
    } catch (err: any) {
      console.error(err.message)
    }

    // close modal
  }
  const closeInvoiceModal = () => {
    setShowInvoiceModal(false)
  }

  const handleApplyFilters = async (e: any, fitlerOption: EProjectFilters) => {
    let localFilters: Record<string, unknown> = {}
    switch (fitlerOption) {
      case EProjectFilters.Status:
        localFilters = handleUpdateStatusFilters(e.target.id)
        break
      case EProjectFilters.Billed:
        localFilters = handleUpdateBilledFilters(e.target.id)
        break
      case EProjectFilters.Paid:
        localFilters = handleUpdatePaidFilters(e.target.id)
        break
    }

    if (Object.keys(localFilters).length > 0) {
      setLoading(true)
      localFilters.userId = userInfo?._id ?? ''
      const res = await trackerService.filterProjects(localFilters)
      setLoading(false)
      setProjects(res.data)
      setShowStatusFilter(false)
      setShowBilledFilter(false)
      setShowPaidFilter(false)
    }
  }

  const handleUpdateStatusFilters = (value: EStatus | string) => {
    let updatedFilters = {}
    if (value !== 'None') {
      updatedFilters = { ...filters, status: value }
      setFilters(updatedFilters)
    } else {
      const localFilter = { ...filters }
      delete localFilter.status
      updatedFilters = { ...localFilter }
      setFilters({ ...updatedFilters })
    }
    return updatedFilters
  }

  const handleUpdateBilledFilters = (value: string) => {
    let updatedFilters = {}
    if (value !== 'None') {
      updatedFilters = { ...filters, isBilled: value === 'true' }
      setFilters(updatedFilters)
    } else {
      const localFilter = { ...filters }
      delete localFilter.isBilled
      updatedFilters = { ...localFilter }
      setFilters({ ...updatedFilters })
    }
    return updatedFilters
  }

  const handleUpdatePaidFilters = (value: string) => {
    let updatedFilters = {}
    if (value !== 'None') {
      updatedFilters = { ...filters, isPaid: value === 'true' }
      setFilters(updatedFilters)
    } else {
      const localFilter = { ...filters }
      delete localFilter.isPaid
      updatedFilters = { ...localFilter }
      setFilters({ ...updatedFilters })
    }
    return updatedFilters
  }

  return (
    <div style={{ position: 'relative' }}>
      {loading && (
        <div style={{ position: 'absolute', width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'rgba(0,0,0,0.2)' }}>
          <CircularProgress />
        </div>
      )}
      <TableContainer component={Paper} elevation={6} style={{ borderRadius: '18px' }}>
        <div style={{ padding: '2%', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', borderBottom: '2px solid rgba(0,0,0,0.1)', backgroundColor: 'var(--app-theme-color-tertiary)' }}>
          <div style={{ width: '90%' }}>
            <Typography variant='h5'>Client Projects List</Typography>
          </div>
          <div style={{ width: invoiceList.length > 0 ? '20%' : '8%', display: 'flex' }}>
            <Button
              variant='outlined'
              style={{ width: '35px', minHeight: '35px' }}
              title='Add New Project'
              onClick={() => {
                setShowAddModal(!showAddModal)
              }}
            >
              <AddIcon style={{ width: '30px' }} />
            </Button>
            {invoiceList.length > 0 && (
              <Button
                variant='outlined'
                style={{ width: '175px', minHeight: '35px', marginLeft: '10px' }}
                title='Create Invoice From Selected Projects'
                onClick={() => {
                  handleCreateInvoice()
                }}
              >
                Create Invoice
              </Button>
            )}
          </div>
        </div>
        <Table sx={{ minWidth: 650 }} aria-label='simple table'>
          <TableHead>
            <TableRow>
              <TableCell align='center'>Invoice Now</TableCell>
              <TableCell>Title</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Start Date</TableCell>
              <TableCell>End Date</TableCell>
              <TableCell align='center'>Bill Hours</TableCell>
              <TableCell align='center'>
                <FilterWrapper>
                  Status
                  <span
                    onClick={() => {
                      setShowStatusFilter(!showStatusFilter)
                    }}
                  >
                    {filters.status ? <FilterAltOffIcon className='filter-icon' /> : <FilterAltIcon className='filter-icon' />}
                  </span>
                  {showStatusFilter && (
                    <div className='filter-inner-wrapper'>
                      <ul className='filter-list'>
                        <li className={`filter-list-item ${filters.status === 'None' ? ' selected' : null} `} id={'None'} onClick={(e) => handleApplyFilters(e, EProjectFilters.Status)}>
                          None
                        </li>
                        <li className={`filter-list-item ${filters.status === EStatus.Complete ? ' selected' : null} `} id={EStatus.Complete} onClick={(e) => handleApplyFilters(e, EProjectFilters.Status)}>
                          Complete
                        </li>
                        <li className={`filter-list-item ${filters.status === EStatus.New ? ' selected' : null} `} id={EStatus.New} onClick={(e) => handleApplyFilters(e, EProjectFilters.Status)}>
                          New
                        </li>
                        <li className={`filter-list-item ${filters.status === EStatus.Wip ? ' selected' : null} `} id={EStatus.Wip} onClick={(e) => handleApplyFilters(e, EProjectFilters.Status)}>
                          Wip
                        </li>
                        <li className={`filter-list-item ${filters.status === EStatus.Hold ? ' selected' : null} `} id={EStatus.Hold} onClick={(e) => handleApplyFilters(e, EProjectFilters.Status)}>
                          Hold
                        </li>
                      </ul>
                    </div>
                  )}
                </FilterWrapper>
              </TableCell>
              <TableCell align='center'>
                <FilterWrapper>
                  Billed{' '}
                  <span
                    onClick={() => {
                      setShowBilledFilter(!showBilledFilter)
                    }}
                  >
                    {filters.isBilled ? <FilterAltOffIcon className='filter-icon' /> : <FilterAltIcon className='filter-icon' />}
                  </span>
                  {showBilledFilter && (
                    <div className='filter-inner-wrapper'>
                      <ul className='filter-list'>
                        <li className={`filter-list-item`} id={'None'} onClick={(e) => handleApplyFilters(e, EProjectFilters.Billed)}>
                          None
                        </li>
                        <li className={`filter-list-item ${filters.isBilled === true ? ' selected' : null} `} id={'true'} onClick={(e) => handleApplyFilters(e, EProjectFilters.Billed)}>
                          Billed
                        </li>
                        <li className={`filter-list-item ${filters.isBilled === false ? ' selected' : null} `} id={'false'} onClick={(e) => handleApplyFilters(e, EProjectFilters.Billed)}>
                          Not Billed
                        </li>
                      </ul>
                    </div>
                  )}
                </FilterWrapper>
              </TableCell>
              <TableCell align='center'>
                <FilterWrapper>
                  Paid{' '}
                  <span
                    onClick={() => {
                      setShowPaidFilter(!showPaidFilter)
                    }}
                  >
                    {filters.isPaid ? <FilterAltOffIcon className='filter-icon' /> : <FilterAltIcon className='filter-icon' />}
                  </span>
                  {showPaidFilter && (
                    <div className='filter-inner-wrapper'>
                      <ul className='filter-list'>
                        <li className={`filter-list-item`} id={'None'} onClick={(e) => handleApplyFilters(e, EProjectFilters.Paid)}>
                          None
                        </li>
                        <li className={`filter-list-item ${filters.isPaid === true ? ' selected' : null} `} id={'true'} onClick={(e) => handleApplyFilters(e, EProjectFilters.Paid)}>
                          Paid
                        </li>
                        <li className={`filter-list-item ${filters.isPaid === false ? ' selected' : null} `} id={'false'} onClick={(e) => handleApplyFilters(e, EProjectFilters.Paid)}>
                          Not Paid
                        </li>
                      </ul>
                    </div>
                  )}
                </FilterWrapper>
              </TableCell>
              <TableCell align='right'>Details</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {projects && projects.length > 0 ? (
              projects.map((project) => (
                <TableRow key={project.title} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell align='center'>
                    {project.status === EStatus.Complete && !project.isBilled && !project.isPaid && (
                      <input
                        value={project._id}
                        type='checkbox'
                        onChange={() => {
                          handleUpdateInvoiceList(project._id)
                        }}
                      />
                    )}
                  </TableCell>
                  <TableCell component='th' scope='row'>
                    {project.title}
                  </TableCell>
                  <TableCell>{project.description}</TableCell>
                  <TableCell>{displaySysTimeToReadable(project.startDate)}</TableCell>
                  <TableCell>{displaySysTimeToReadable(project.endDate)}</TableCell>
                  <TableCell align='center'>{calculateBillableHours(project)}</TableCell>
                  <TableCell align='center'>
                    <Chip label={`${project.status}`} style={{ backgroundColor: determineChipColor(project.status), minWidth: '50px' }} />
                  </TableCell>
                  <TableCell align='center'>{project.isBilled ? <Chip label='Billed' style={{ backgroundColor: 'rgba(136, 190, 125, .5)' }} /> : <Chip label='Not Billed' style={{ backgroundColor: 'rgba(233, 30, 99, .6)' }} />}</TableCell>
                  <TableCell align='center'>{project.isPaid ? <Chip label='Paid' style={{ backgroundColor: 'rgba(136, 190, 125, .5)' }} /> : <Chip label='Not Paid' style={{ backgroundColor: 'rgba(233, 30, 99, .6)' }} />}</TableCell>
                  <TableCell align='right'>
                    <Button
                      variant='outlined'
                      size='small'
                      onClick={() => {
                        navigate(`/client/${clientId}/project/${project._id}`)
                      }}
                    >
                      details
                    </Button>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableCell>
                <Typography variant='h5'>No Data</Typography>
              </TableCell>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <AppModal show={showAddModal} closeBtnAction={closeAddProjectModal} saveBtnAction={saveProject}>
        <AddProjectWrapper>
          <h2 className='form-title'>Add Project</h2>
          <form className='add-project-form'>
            <select
              className='app-form-input'
              onChange={(e) => {
                setStatus(e.target.value)
              }}
            >
              <option selected disabled>
                *Select Status
              </option>
              {statusData.map((_status, index: number) => {
                return (
                  <option key={index} value={_status}>
                    {_status}
                  </option>
                )
              })}
            </select>
            <input
              className='app-form-input'
              placeholder='*Title'
              value={title}
              onChange={(e) => {
                setitle(e.target.value)
              }}
            />
            <input
              className='app-form-input'
              placeholder='*Description'
              value={description}
              onChange={(e) => {
                setDescription(e.target.value)
              }}
            />
            <textarea
              className='notes-text-input'
              placeholder='Add Notes...'
              rows={4}
              value={notes}
              onChange={(e) => {
                setNotes(e.target.value)
              }}
            ></textarea>
            {errorMsg && <p className='error-txt'>{errorMsg}</p>}
          </form>
        </AddProjectWrapper>
      </AppModal>
      <AppModal show={showInvoiceModal} closeBtnAction={closeInvoiceModal} saveBtnAction={handleSaveInvoice} size='XL'>
        {/* <h2>Invoice</h2> */}
        <PDFInvoice invoiceData={invoiceData} />
      </AppModal>
    </div>
  )
}

export default ProjectTable

const AddProjectWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .form-title {
    font-size: 45px;
    background-color: var(--app-theme-color-tertiary);
    width: 100%;
    height: 100px;
    margin-top: 0;
    border-radius: 18px 18px 0 0;
    text-align: center;
  }

  .add-project-form {
    display: flex;
    flex-direction: column;
    padding: 2%;
    min-height: 250px;

    .notes-text-input {
      border-radius: 8px;
    }
  }

  .error-txt {
    color: #d32f2f;
    font-size: 14px;
    text-align: center;
  }
`
const FilterWrapper = styled.div`
  position: relative;
  .filter-icon {
    font-size: 15px;
    color: var(--primary-text-color);
  }
  .filter-inner-wrapper {
    position: absolute;
    background-color: #fff;
    min-width: 100px;
    box-shadow: 0 8px 15px 0 rgba(0, 0, 0, 0.2);
    border: 1px solid rgba(0, 0, 0, 0.2);

    .filter-list {
      list-style: none;
      width: 100%;
      padding: 0;
      margin: 0;

      .filter-list-item {
        width: 100%;
        text-align: center;
        cursor: pointer;
        padding: 5px 0;
        :hover {
          background-color: var(--app-theme-color-tertiary);
        }
      }
      .selected {
        background-color: var(--app-theme-color-tertiary);
      }
    }
  }
`
