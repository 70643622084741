import React, { useState } from 'react'
import { Outlet } from 'react-router-dom'
import styled from 'styled-components'
import NavigationBar from './components/NavigationBar'

const navigationLinks = [
  { id: 1, linkName: 'Account', linkTo: 'account' },
  { id: 2, linkName: 'Password', linkTo: 'password' },
]

const Settings = () => {
  return (
    <SettingsContainer>
      <div className='account-settings-header'>
        <h2>Account Settings</h2>
        <h3>Update your profile & account Settings</h3>
      </div>
      <div className='setting-navigation'>
        <NavigationBar navigationLinks={navigationLinks} />
      </div>
      <div className='view-container'>
        <Outlet />
      </div>
    </SettingsContainer>
  )
}

export default Settings

const SettingsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1700px;
  padding: 2%;
  margin: 0 auto;

  .account-settings-header {
    h2 {
      margin: 0;
    }
    h3 {
      margin: 0;
      color: rgba(0, 0, 0, 0.3);
    }
  }
  .setting-navigation {
    width: 100%;
  }

  .view-container {
    width: 100%;
  }
`
