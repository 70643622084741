import { Navigate } from 'react-router-dom'
import * as storageService from '../../services/storage/app-storage-api'
import { useJwt } from 'react-jwt'
import { useUserStatus } from '../../hooks/useUserStatus'

export const PrivateRoute = ({ children }: any) => {
  const accessToken = storageService.getStorageValue(storageService.EStorageKeys.ACCESS_TOKEN)
  const { isExpired } = useJwt(accessToken as string)
  const { isLoggedIn } = useUserStatus()

  if (isExpired || !isLoggedIn) {
    return <Navigate to='/login' />
  }

  return children
}
