import React from 'react'
import styled from 'styled-components'

interface ISectionWrapperProps {
  children: any
  title?: string
}

const SectionWrapper: React.FC<ISectionWrapperProps> = ({ children, title }) => {
  return (
    <PageWrapper>
      {title && <h3>{title}</h3>}
      {children}
    </PageWrapper>
  )
}

export default SectionWrapper

const PageWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  border: 2px solid rgba(0, 0, 0, 0.1);
  min-height: calc(100vh - 300px);
  padding: 3%;

  h3 {
    color: rgba(0, 0, 0, 0.5);
  }
`
