import { TextField } from '@mui/material'
import React from 'react'
import styled from 'styled-components'

interface ICustomFormInputProps {
  label?: string
  name: string
  value: any
  handleChange: (arg: any) => void
  onBlur?: any
  placeHolder?: string
  error?: string
  inputSize?: string
  inputType?: 'text' | 'password' | 'number'
}

const CustomFormInput: React.FC<ICustomFormInputProps> = ({ label, name, value, handleChange, onBlur, placeHolder, error, inputSize = '90%', inputType = 'text' }) => {
  return (
    <InputWrapper>
      <TextField sx={{ width: inputSize }} type={inputType} label={label} name={name} value={value} onChange={handleChange} onBlur={onBlur} placeholder={placeHolder} variant='outlined' />
      <div className='error-container'>{error && <p className='error-txt'>*{error}</p>}</div>
    </InputWrapper>
  )
}

export default CustomFormInput

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  .error-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 20px;
    width: 90%;
    .error-txt {
      margin: 5px;
      color: crimson;
    }
  }
`
