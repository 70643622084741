import React from 'react'
import styled from 'styled-components'
import placeHolder from '../assets/common/person-placeholder.jpg'

interface IUserInfoProps {
  name: string
  profileImg?: string
}

const UserInfo: React.FC<IUserInfoProps> = ({ name, profileImg }) => {
  return (
    <UserInfoWrapper>
      <img src={profileImg ? profileImg : placeHolder} alt='profile' className='profile-pic' />
      <p className='user-name-txt'>{name}</p>
    </UserInfoWrapper>
  )
}

export default UserInfo

const UserInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  .profile-pic {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
    object-position: center;
  }
  .user-name-txt {
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    margin-top: 3px;
  }
`
