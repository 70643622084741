import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'
import { useAppSelector } from '../../hooks/useRedux'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Chip from '@mui/material/Chip'
import AddIcon from '@mui/icons-material/Add'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Paper from '@mui/material/Paper'
import IInvoicePDF from '../../models/interfaces/pdfs/IInvoicePDF'
import { trackerService } from '../../services'
import { displaySysTimeToReadable } from '../../common/globalHelperFunctions'

const InvoiceTable = () => {
  const navigate = useNavigate()
  const { userInfo } = useAppSelector((state) => state.user)
  const [invoices, setInvoices] = useState<Array<IInvoicePDF>>([])

  useEffect(() => {
    userInfo &&
      (async () => {
        const res = await trackerService.getInvoices(userInfo?._id)
        setInvoices(res.data)
      })()
  }, [userInfo])

  return (
    <TableContainer component={Paper} elevation={6} style={{ borderRadius: '18px' }}>
      <div style={{ padding: '2%', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', borderBottom: '2px solid rgba(0,0,0,0.1)', backgroundColor: 'var(--app-theme-color-tertiary)' }}>
        <div style={{ width: '90%' }}>
          <Typography variant='h5'>Invoices List</Typography>
        </div>
        <div style={{ width: '8%', display: 'flex' }}>
          <Button
            variant='outlined'
            style={{ width: '175px', minHeight: '35px', marginLeft: '10px' }}
            title='Create Invoice From Selected Projects'
            onClick={() => {
              // handleCreateInvoice()
            }}
          >
            Create Invoice
          </Button>
        </div>
      </div>
      <Table sx={{ minWidth: 650 }} aria-label='simple table'>
        <TableHead>
          <TableRow>
            <TableCell align='center'>Invoice Number</TableCell>
            <TableCell align='center'>Client</TableCell>
            <TableCell align='center'>Date Billed</TableCell>
            <TableCell align='center'>Terms</TableCell>
            <TableCell align='center'>Projects Billed (Count)</TableCell>
            <TableCell align='center'>Delivery Status</TableCell>
            <TableCell align='center'>Paid</TableCell>
            <TableCell align='center'>View</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {invoices && invoices.length > 0 ? (
            invoices.map((invoice) => (
              <TableRow key={invoice._id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell align='center'>{invoice.invoiceNumber}</TableCell>
                <TableCell align='center'>{invoice.clientInformation.businessName}</TableCell>
                <TableCell component='th' scope='row'>
                  {displaySysTimeToReadable(invoice.invoiceDate as any)}
                </TableCell>
                <TableCell align='center'>{invoice.terms}</TableCell>
                <TableCell align='center'>{invoice.projectIds.length}</TableCell>
                <TableCell align='center'>{invoice.deliveryStatus === 'Not Sent' ? <Chip label={invoice.deliveryStatus} style={{ backgroundColor: 'rgba(233, 30, 99, .6)', minWidth: 70, fontWeight: 'bold' }} /> : <Chip label={invoice.deliveryStatus} style={{ backgroundColor: 'rgba(136, 190, 125, .5)', minWidth: 70, fontWeight: 'bold' }} />}</TableCell>
                <TableCell align='center'>{invoice.isPaid ? <Chip label='Paid' style={{ backgroundColor: 'rgba(136, 190, 125, .5)', minWidth: 70, fontWeight: 'bold' }} /> : <Chip label='Unpaid' style={{ backgroundColor: 'rgba(233, 30, 99, .6)', minWidth: 70, fontWeight: 'bold' }} />}</TableCell>

                <TableCell align='center'>
                  <Button
                    variant='outlined'
                    size='small'
                    onClick={() => {
                      navigate(`/invoice/${invoice._id} `)
                    }}
                  >
                    View
                  </Button>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableCell>
              <Typography variant='h5'>No Data</Typography>
            </TableCell>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default InvoiceTable
