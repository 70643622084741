import ApiClient from './ApiClient'
import { ApiConfiguration } from './config/ApiConfiguration'
import TrackerService, { TrackerApiClient } from './TrackerService'
import { EStorageKeys, getStorageValue } from './storage/app-storage-api'

//Tracker Service
const trackerApiConfig = new ApiConfiguration()
trackerApiConfig.baseURL = process.env.REACT_APP_TRACKER_SERVCE_URL || ''
trackerApiConfig.accessToken = getStorageValue(EStorageKeys.ACCESS_TOKEN) || ''
const trackerApiClient = new TrackerApiClient(new ApiClient(trackerApiConfig))
export const trackerService = new TrackerService(trackerApiClient)
