import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useNavigate, useParams } from 'react-router-dom'
import IClient from '../../models/interfaces/clients/IClient'
import { trackerService } from '../../services'
import toast from 'react-hot-toast'

import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Paper from '@mui/material/Paper'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ModeEditIcon from '@mui/icons-material/ModeEdit'
import SaveIcon from '@mui/icons-material/Save'
import CancelIcon from '@mui/icons-material/Cancel'
import ProjectTable from '../../components/client-detail/ProjectTable'

import IUpdateClientReq from '../../models/interfaces/clients/IUpdateClientReq'
import BasicCard from '../../components/global/BasicCard'
import Divider from '@mui/material/Divider'
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip'
import InfoIcon from '@mui/icons-material/Info'
import { EStatus } from '../../models/Enums/EStatus'
import { useAppDispatch } from '../../hooks/useRedux'
import { setClient as setGlobalStateClient } from '../../store/features/clients/clientsSlice'

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => <Tooltip {...props} classes={{ popper: className }} />)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#505050',
    color: '#fff',
    fontSize: 11,
  },
}))

const ClientDetail = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { clientId } = useParams<{ clientId: string }>()
  const [client, setClient] = useState<IClient | null>(null)
  const [editClient, setEditClient] = useState<boolean>(false)
  const [businessName, setBusinessName] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [phone, setPhone] = useState<string>('')
  const [billRate, setBillRate] = useState<number>(0)
  const [addressOne, setAddressOne] = useState<string>('')
  const [addressTwo, setAddressTwo] = useState<string>('')
  const [city, setCity] = useState<string>('')
  const [state, setState] = useState<string>('')
  const [postalCode, setPostalCode] = useState<string>('')
  const [firstName, setFirstName] = useState<string>('')
  const [lastName, setLastName] = useState<string>('')
  const [contactEmail, setContactEmail] = useState<string>('')
  const [contactPhone, setContactPhone] = useState<string>('')
  const [notes, setNotes] = useState<string>('')

  const [reportCompleteUnbilled, setReportCompleteUnbilled] = useState<{ status: string; charge: number; totalHoursBilled: number } | null>(null)
  const [reportCompletebilled, setReportCompletebilled] = useState<{ status: string; charge: number; totalHoursBilled: number } | null>(null)

  useEffect(() => {
    clientId &&
      (async () => {
        const res = await trackerService.getClient(clientId)

        if (res.success) {
          setClient(res.data)
          dispatch(setGlobalStateClient(res.data))
          const getCompleteUnbilled = await trackerService.getClientsProjectStatusReportForIncome(clientId, {
            status: EStatus.Complete,
            billRate: res.data.billRate,
            isBilled: false,
            isPaid: false,
          })
          setReportCompleteUnbilled(getCompleteUnbilled.data)
          const getCompleteBilled = await trackerService.getClientsProjectStatusReportForIncome(clientId, {
            status: EStatus.Complete,
            billRate: res.data.billRate,
            isBilled: true,
            isPaid: false,
          })
          setReportCompletebilled(getCompleteBilled.data)
        }
      })()
  }, [])

  const handleEditProject = () => {
    const isEditing = !editClient
    setEditClient(isEditing)
    if (isEditing) {
      setBusinessName(client?.businessName ?? '')
      setEmail(client?.email ?? '')
      setPhone(client?.phone ?? '')
      setBillRate(client?.billRate ?? 0)
      setAddressOne(client?.address.addressOne ?? '')
      setAddressTwo(client?.address.addressTwo ?? '')
      setCity(client?.address.city ?? '')
      setState(client?.address.state ?? '')
      setPostalCode(client?.address.postalCode ?? '')
      setFirstName(client?.contact.firstName ?? '')
      setLastName(client?.contact.lastName ?? '')
      setContactEmail(client?.contact.email ?? '')
      setContactPhone(client?.contact.phone ?? '')
      setNotes(client?.notes ?? '')
    }
  }

  const updateSelectedClient = async () => {
    if (!businessName || !phone || !billRate || !addressOne || !city || !state || !postalCode || !firstName || !lastName || !email || !contactEmail) {
      toast.error('Required Fields Missing.')
      return
    }
    const updateClientReq: IUpdateClientReq = {
      businessName,
      phone,
      email,
      billRate,
      notes,
      address: {
        addressOne,
        addressTwo,
        city,
        postalCode,
        state,
      },
      contact: {
        firstName,
        lastName,
        email: contactEmail,
        phone: contactPhone,
      },
    }
    const updateRes = await trackerService.updateClient(clientId || '', updateClientReq)
    if (updateRes.success) {
      toast.success('Updated Client Successful')
      clearInputs()
      setEditClient(false)
      const res = await trackerService.getClient(clientId || '')
      setClient(res.data)
    } else if (!updateRes.success) {
      toast.error('Could Not Update Client.')
    }
  }

  const clearInputs = () => {
    setBusinessName('')
    setEmail('')
    setPhone('')
    setBillRate(0)
    setAddressOne('')
    setAddressTwo('')
    setCity('')
    setState('')
    setPostalCode('')
    setFirstName('')
    setLastName('')
    setContactEmail('')
    setContactPhone('')
    setNotes('')
  }

  return (
    <ClientDetailWrapper>
      <div className='heading-wrapper'>
        <Typography variant='h3' className='title'>
          Client Detail - {client?.businessName}
        </Typography>
        <Button variant='contained' style={{ height: '40px' }} onClick={() => navigate('/clients')}>
          <ArrowBackIcon />
        </Button>
      </div>
      <Divider />
      <div>
        <h2>Projects Report</h2>
      </div>
      <div style={{ width: '100%', display: 'flex', flexFlow: 'row wrap', justifyContent: 'space-evenly', margin: '2% 0' }}>
        <BasicCard title={'Complete & Unbilled'} value={`$${reportCompleteUnbilled?.charge || 0}`} color={'rgb(253, 155, 61)'}>
          <CustomTooltip title='This dollar amount is calculated for "Complete" Projects and "Complete" Tasks only.' arrow>
            <InfoIcon style={{ color: '#505050' }} />
          </CustomTooltip>
        </BasicCard>
        <BasicCard title={'Complete & Billed'} value={`$${reportCompletebilled?.charge || 0}`} color={'#88be7d'}>
          <CustomTooltip title='This dollar amount is calculated for "Complete" Projects and "Complete" Tasks only. Not "Paid" status.' arrow>
            <InfoIcon style={{ color: '#505050' }} />
          </CustomTooltip>
        </BasicCard>
      </div>
      <Divider />

      <div className='content-wrapper'>
        <Paper elevation={4} style={{ width: '100%', padding: '2%', borderRadius: '18px' }}>
          <div className='edit-btn'>
            <Button className='top-edit-btn' style={{ color: editClient ? '#d32f2f' : '' }} onClick={() => handleEditProject()}>
              {editClient ? (
                <>
                  Cancel
                  <CancelIcon />
                </>
              ) : (
                <>
                  Edit
                  <ModeEditIcon />
                </>
              )}
            </Button>
            {editClient && (
              <Button className='top-edit-btn' onClick={() => updateSelectedClient()}>
                Save
                <SaveIcon />
              </Button>
            )}
          </div>
          <div className='row'>
            {editClient ? (
              <div className='col round-border'>
                <Typography variant='h5'>Edit Business Info</Typography>
                <div className='content-lbl-wrapper'>
                  <p className='label'>Business Name:</p>
                  <input
                    className='app-form-input'
                    type='text'
                    value={businessName}
                    onChange={(e) => {
                      setBusinessName(e.target.value)
                    }}
                  />
                </div>
                <div className='content-lbl-wrapper'>
                  <p className='label'>Email:</p>
                  <input
                    className='app-form-input'
                    type='text'
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value)
                    }}
                  />
                </div>
                <div className='content-lbl-wrapper'>
                  <p className='label'>Phone:</p>
                  <input
                    className='app-form-input'
                    type='text'
                    value={phone}
                    onChange={(e) => {
                      setPhone(e.target.value)
                    }}
                  />
                </div>
                <div className='content-lbl-wrapper'>
                  <p className='label'>Bill Rate ($):</p>
                  <input
                    className='app-form-input'
                    type='number'
                    min={0}
                    step='0.01'
                    value={billRate}
                    onChange={(e) => {
                      setBillRate(Number(e.target.value))
                    }}
                  />
                </div>
                <div className='content-lbl-wrapper'>
                  <p className='label'>Notes:</p>
                  <textarea
                    className='app-form-input'
                    rows={5}
                    value={notes}
                    onChange={(e) => {
                      setNotes(e.target.value)
                    }}
                  />
                </div>
              </div>
            ) : (
              <div className='col round-border'>
                <Typography variant='h5'>Business Info</Typography>
                <div className='content-lbl-wrapper'>
                  <p className='label'>Email:</p>
                  <p className='titl-txt'>{client?.email}</p>
                </div>
                <div className='content-lbl-wrapper'>
                  <p className='label'>Phone:</p>
                  <p className='titl-txt'>{client?.phone}</p>
                </div>
                <div className='content-lbl-wrapper'>
                  <p className='label'>Bill Rate:</p>
                  <p className='titl-txt'>${client?.billRate.toFixed(2)}</p>
                </div>
                <div className='content-lbl-wrapper'>
                  <p className='label'>notes:</p>
                  <p className='titl-txt'>{client?.notes}</p>
                </div>
              </div>
            )}

            {editClient ? (
              <div className='col round-border'>
                <Typography variant='h5'>Edit Address</Typography>
                <div className='content-lbl-wrapper'>
                  <p className='label'>Address One:</p>
                  <input
                    className='app-form-input'
                    type='text'
                    value={addressOne}
                    onChange={(e) => {
                      setAddressOne(e.target.value)
                    }}
                  />
                </div>
                {client?.address.addressTwo && (
                  <div className='content-lbl-wrapper'>
                    <p className='label'>Address Two:</p>
                    <input
                      className='app-form-input'
                      type='text'
                      value={addressTwo}
                      onChange={(e) => {
                        setAddressTwo(e.target.value)
                      }}
                    />
                  </div>
                )}
                <div className='content-lbl-wrapper'>
                  <p className='label'>City:</p>
                  <input
                    className='app-form-input'
                    type='text'
                    value={city}
                    onChange={(e) => {
                      setCity(e.target.value)
                    }}
                  />
                </div>
                <div className='content-lbl-wrapper'>
                  <p className='label'>State:</p>
                  <input
                    className='app-form-input'
                    type='text'
                    value={state}
                    onChange={(e) => {
                      setState(e.target.value)
                    }}
                  />
                </div>
                <div className='content-lbl-wrapper'>
                  <p className='label'>Postal Code:</p>
                  <input
                    className='app-form-input'
                    type='text'
                    value={postalCode}
                    onChange={(e) => {
                      setPostalCode(e.target.value)
                    }}
                  />
                </div>
              </div>
            ) : (
              <div className='col round-border'>
                <Typography variant='h5'>Address</Typography>
                <div className='content-lbl-wrapper'>
                  <p className='label'>Address One:</p>
                  <p className='titl-txt'>{client?.address.addressOne}</p>
                </div>
                {client?.address.addressTwo && (
                  <div className='content-lbl-wrapper'>
                    <p className='label'>Address Two:</p>
                    <p className='titl-txt'>{client?.address.addressTwo}</p>
                  </div>
                )}
                <div className='content-lbl-wrapper'>
                  <p className='label'>City:</p>
                  <p className='titl-txt'>{client?.address.city}</p>
                </div>
                <div className='content-lbl-wrapper'>
                  <p className='label'>State:</p>
                  <p className='titl-txt'>{client?.address.state}</p>
                </div>
                <div className='content-lbl-wrapper'>
                  <p className='label'>Postal Code:</p>
                  <p className='titl-txt'>{client?.address.postalCode}</p>
                </div>
              </div>
            )}
          </div>
          <div className='row'>
            {editClient ? (
              <div className='col round-border'>
                <Typography variant='h5'>Edit Contact</Typography>
                <div className='content-lbl-wrapper'>
                  <p className='label'>First Name:</p>
                  <input
                    className='app-form-input'
                    type='text'
                    value={firstName}
                    onChange={(e) => {
                      setFirstName(e.target.value)
                    }}
                  />
                </div>
                <div className='content-lbl-wrapper'>
                  <p className='label'>Last Name:</p>
                  <input
                    className='app-form-input'
                    type='text'
                    value={lastName}
                    onChange={(e) => {
                      setLastName(e.target.value)
                    }}
                  />
                </div>
                <div className='content-lbl-wrapper'>
                  <p className='label'>Email:</p>
                  <input
                    className='app-form-input'
                    type='text'
                    value={contactEmail}
                    onChange={(e) => {
                      setContactEmail(e.target.value)
                    }}
                  />
                </div>
                <div className='content-lbl-wrapper'>
                  <p className='label'>Phone:</p>
                  <input
                    className='app-form-input'
                    type='text'
                    value={contactPhone}
                    onChange={(e) => {
                      setContactPhone(e.target.value)
                    }}
                  />
                </div>
              </div>
            ) : (
              <div className='col round-border'>
                <Typography variant='h5'>Contact</Typography>
                <div className='content-lbl-wrapper'>
                  <p className='label'>Name:</p>
                  <p className='titl-txt'>{client?.contact.firstName + ' ' + client?.contact.lastName}</p>
                </div>
                <div className='content-lbl-wrapper'>
                  <p className='label'>Email:</p>
                  <p className='titl-txt'>{client?.contact.email}</p>
                </div>
                <div className='content-lbl-wrapper'>
                  <p className='label'>Phone:</p>
                  <p className='titl-txt'>{client?.contact.phone}</p>
                </div>
              </div>
            )}
          </div>
        </Paper>
      </div>
      <div className='table-wrapper'>
        <ProjectTable clientId={clientId || null} />
      </div>
    </ClientDetailWrapper>
  )
}

export default ClientDetail

const ClientDetailWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2%;
  width: 100%;
  .title {
    width: 90%;
  }

  .heading-wrapper {
    display: flex;
    width: 100%;
    margin-bottom: 5%;
  }

  .round-border {
    border: 2px solid rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    padding: 1%;
    margin: 1%;
  }

  .content-wrapper {
    margin-top: 5%;
    width: 100%;
    display: flex;
    .row {
      display: flex;
      width: 100%;
      .col {
        width: 100%;
        .content-lbl-wrapper {
          display: flex;

          .label {
            width: 25%;
            font-weight: 600;
          }
        }
      }
    }
  }

  .table-wrapper {
    margin-top: 2%;
  }

  .edit-btn {
    width: 100%;
    display: flex;
    justify-content: flex-end;

    .top-edit-btn {
      margin: 0 10px;
    }
  }
`
