import React from 'react'
import BasicCard from '../../components/global/BasicCard'
import { motion } from 'framer-motion'
import { useClientStore } from './hooks/useClientStore'

const SpingAnimationFrame = ({ children }: any) => {
  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.5 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{
        duration: 0.3,
        ease: [0, 0.71, 0.2, 1.01],
        scale: {
          type: 'spring',
          damping: 5,
          stiffness: 100,
          restDelta: 0.001,
        },
      }}
    >
      {children}
    </motion.div>
  )
}

const ClientReportCenter = () => {
  const { statusReport } = useClientStore()
  return (
    <div style={{ width: '100%', display: 'flex', flexFlow: 'row wrap', justifyContent: 'space-evenly', margin: '2% 0' }}>
      <SpingAnimationFrame>
        <BasicCard title={'Complete'} value={statusReport?.completeCount ?? 0} color={'#88be7d'} />
      </SpingAnimationFrame>
      <SpingAnimationFrame>
        <BasicCard title={'New'} value={statusReport?.newCount ?? 0} color={'#fd9b3d'} />
      </SpingAnimationFrame>
      <SpingAnimationFrame>
        <BasicCard title={'Wip'} value={statusReport?.wipCount ?? 0} color={'#e3e2f4'} />
      </SpingAnimationFrame>
      <SpingAnimationFrame>
        <BasicCard title={'Hold'} value={statusReport?.holdCount ?? 0} color={'#caecec'} />
      </SpingAnimationFrame>
    </div>
  )
}

export default ClientReportCenter
