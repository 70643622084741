import React, { useState, useEffect } from 'react'
import { Page, Text, View, Document, StyleSheet, PDFViewer, BlobProvider } from '@react-pdf/renderer'
import IInvoicePDF from '../../../models/interfaces/pdfs/IInvoicePDF'

const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: '#E4E4E4',
  },
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  header: {
    width: '100%',
    textAlign: 'right',
    fontSize: 30,
    marginBottom: 20,
  },
  section: {
    marginTop: 20,
    marginBottom: 10,
    fontWeight: 'extrabold',
  },
  heading: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  boldHeading: {
    fontSize: 14,
    fontWeight: 800,
  },
  userInfo: {
    marginBottom: 10,
    fontSize: 14,
  },
  clientInfo: {
    marginTop: 10,
  },
  clientInnerInfo: {
    marginTop: 5,
    fontSize: 14,
  },
  invoiceInfo: {
    fontSize: 14,
    textAlign: 'justify',
    fontWeight: 'extrabold',
  },
  sectionBilling: {
    marginTop: 50,
    flexGrow: 1,
  },
  billArea: {
    fontSize: 12,
    display: 'flex',
    flexDirection: 'column',
  },
  tHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    border: 2,
    padding: 5,
    backgroundColor: '#e6ebf1',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderBottom: 1,
    borderColor: '#e3e3e3',
    paddingTop: 10,
    paddingBottom: 10,
    paddingRight: 5,
    paddingLeft: 5,
    alignItems: 'center',
  },
  title: {
    width: 50,
  },
  description: {
    width: 100,
    textAlign: 'left',
  },
  hoursTxt: {
    marginRight: 10,
  },
  totalRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 10,
  },
  amountDueTxt: {
    border: 2,
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 5,
    fontWeight: 700,
  },
  footer: {
    fontWeight: 900,
    fontSize: 12,
  },
  paidWrapper: {
    marginTop: 20,
    border: 2,
    borderColor: 'red',
    borderRadius: 50,
    width: 105,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  paidTxt: {
    color: 'red',
    fontSize: 30,
    textAlign: 'center',
  },
})

/*
Props required by this Components
User info:
    Pass all object attributes from the user currently logged in. (in state)
Client Info:
    Pass all the the Cleint object Attributes (in state)
Client Project:
    We have and collection of project Ids that we are storing from the table selections. The best thing to do is pass in the payload for the only selected projects 
    and then we can itterate though the tasks and print out what is complete status and taskHours * billRate
Invoice:
    We need to an Invoice entity that hold Invoice information so when we do recall an invoice for later purpose we populate the information accordingly
    We will need to keep a table just for the input number and increment everytime we save a new invoice

*/

interface IInvoiceProps {
  invoiceData: IInvoicePDF | null
  setPDFFile?: (arg: any) => void
}

const PDFInvoice: React.FC<IInvoiceProps> = ({ invoiceData, setPDFFile }) => {
  const [blobImage, setBlobImage] = useState<any | null>(null)

  useEffect(() => {
    if (blobImage && setPDFFile) {
      setPDFFile(blobImage)
    }
  }, [blobImage])

  const formatDate = (date: Date | null) => {
    const tansDate = date ? new Date(date) : null
    return tansDate ? `${tansDate.getMonth() + 1}/${tansDate.getDate()}/${tansDate.getFullYear()}` : ''
  }

  return (
    <BlobProvider
      document={
        <Document style={{ minHeight: '80vh', width: '70%' }}>
          <Page id='client-invoice' size='A4' style={styles.body}>
            <View style={styles.header}>
              <Text>INVOICE</Text>
            </View>
            <View style={styles.heading}>
              <View>
                <View style={styles.userInfo}>
                  <Text>{invoiceData?.userInformation.businessName}</Text>
                  <Text>{invoiceData?.userInformation.streetOne}</Text>
                  {invoiceData?.userInformation.streetTwo && <Text>{invoiceData?.userInformation.streetTwo}</Text>}
                  <Text>
                    {invoiceData?.userInformation.city},&nbsp;{invoiceData?.userInformation.state},&nbsp;{invoiceData?.userInformation.postalCode}
                  </Text>
                  <Text>{invoiceData?.userInformation.phone}</Text>
                  <Text>{invoiceData?.userInformation.email}</Text>
                </View>
                <View style={styles.clientInfo}>
                  <Text style={styles.boldHeading}>Bill to:</Text>
                  <View style={styles.clientInnerInfo}>
                    <Text>{invoiceData?.clientInformation.businessName}</Text>
                    {invoiceData?.clientInformation.streetTwo && <Text>{invoiceData?.clientInformation.streetTwo}</Text>}
                    <Text>
                      {invoiceData?.clientInformation.city},&nbsp;{invoiceData?.clientInformation.state}&nbsp;{invoiceData?.clientInformation.postalCode}
                    </Text>
                  </View>
                </View>
              </View>
              <View style={styles.invoiceInfo}>
                <Text>Invoice #: {invoiceData?.invoiceNumber}</Text>
                <Text>Invoice Date: {formatDate(invoiceData?.invoiceDate ?? null)}</Text>
                <Text>Terms: {invoiceData?.terms}</Text>
                {invoiceData?.isPaid && (
                  <View style={styles.paidWrapper}>
                    <Text style={styles.paidTxt}> PAID</Text>
                  </View>
                )}
              </View>
            </View>

            <View style={styles.sectionBilling}>
              <View style={styles.billArea}>
                <View style={styles.tHeader}>
                  <Text style={styles.title}>Project</Text>
                  <Text style={styles.description}>Description</Text>
                  <Text>Cost/Hr Rate</Text>
                  <Text>Qnty/Hours</Text>
                  <Text>Amount</Text>
                </View>
                {invoiceData?.projectListItems.map((d) => {
                  return (
                    <View style={styles.row}>
                      <Text style={styles.title}>{d.title}</Text>
                      <Text style={styles.description}>{d.description}</Text>
                      <Text style={styles.hoursTxt}>${invoiceData?.clientInformation.billRate.toFixed(2)}</Text>
                      <Text style={styles.hoursTxt}>{d.hours}</Text>
                      <Text style={styles.hoursTxt}>${d.charge.toFixed(2)}</Text>
                    </View>
                  )
                })}
              </View>
              <View style={styles.totalRow}>
                <Text>INVOICE TOTAL</Text>
                <Text style={styles.amountDueTxt}>${invoiceData?.invoiceTotal.toFixed(2)}</Text>
              </View>
            </View>
            <View style={styles.footer}>
              <Text>Thank you for your business!</Text>
            </View>
          </Page>
        </Document>
      }
    >
      {({ blob, url, loading }) => {
        if (!loading && blob) {
          setBlobImage(blob)
        }
        //

        // return loading ? <h4>Loading Invoice....</h4> : <iframe title='invoice-pdf' src={url || ''} style={{ minHeight: '80vh', width: '80%' }} />
        return loading && !blob ? <h4>Loading Invoice....</h4> : <iframe src={blob ? URL.createObjectURL(blob as any) : ''} style={{ minHeight: '80vh', width: '80%' }} />
      }}
    </BlobProvider>
  )
}

export default PDFInvoice
