import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useSignupContextState, useSignupContextActions } from '../useSignupContext'

import * as Yup from 'yup'
import { useFormik } from 'formik'

import CustomFormInput from './components/CustomFormInput'
import StepLayoutWrapper from './components/StepLayoutWrapper'
import CheckIcon from '@mui/icons-material/Check'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'

import { Typography } from '@mui/material'

const Password = () => {
  const { password, currenStep, steps } = useSignupContextState()
  const { updatePassword, updateStepComplete } = useSignupContextActions()
  const [viewPassword, setViewPassword] = useState<boolean>(false)
  const [passwordRules, setPasswordRules] = useState<{ length: boolean; lowerCase: boolean; upperCase: boolean; numbers: boolean; specialChars: boolean }>({
    length: false,
    lowerCase: false,
    upperCase: false,
    numbers: false,
    specialChars: false,
  })
  const getCharacterValidationError = (str: string) => {
    return `Your password must have at least 1 ${str} character`
  }

  const initialValues: { password: string; confirmedPassword: string } = password ? { password: password, confirmedPassword: password } : { password: '', confirmedPassword: '' }
  const validationSchema = Yup.object({
    password: Yup.string()
      .trim()
      .min(8, 'Password must have at least 8 characters')
      .max(15, 'Password must have no more than 15 characters')
      // different error messages for different requirements
      .matches(/[0-9]/, getCharacterValidationError('digit'))
      .matches(/[a-z]/, getCharacterValidationError('lowercase'))
      .matches(/[A-Z]/, getCharacterValidationError('uppercase')),
    confirmedPassword: Yup.string()
      .oneOf([Yup.ref('password')], 'Passwords does not match')
      .required('Required'),
  })

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      console.log(values)
    },
  })

  useEffect(() => {
    const { value } = formik.getFieldProps('password')
    validatePassword(value)
  }, [formik.values])

  const validatePassword = (value: string) => {
    if (value) {
      const numberReg = /[0-9]/
      const upperCaseReg = /[A-Z]/
      const lowerCaseReg = /[a-z]/
      const specialCharsReg = /[\^#*&!$%?@]+/

      const isCorrectlength = value.length >= 8 && value.length <= 15
      const hasNumbers = numberReg.test(value)
      const hasUpperCase = upperCaseReg.test(value)
      const hasLowerCase = lowerCaseReg.test(value)
      const hasSpecialCharsReg = specialCharsReg.test(value)

      const updatedPasswordRules: { length: boolean; lowerCase: boolean; upperCase: boolean; numbers: boolean; specialChars: boolean } = {
        length: isCorrectlength,
        lowerCase: hasLowerCase,
        upperCase: hasUpperCase,
        numbers: hasNumbers,
        specialChars: hasSpecialCharsReg,
      }
      setPasswordRules(updatedPasswordRules)
    } else {
      const updatedPasswordRules: { length: boolean; lowerCase: boolean; upperCase: boolean; numbers: boolean; specialChars: boolean } = {
        length: false,
        lowerCase: false,
        upperCase: false,
        numbers: false,
        specialChars: false,
      }
      setPasswordRules(updatedPasswordRules)
    }
  }

  useEffect(() => {
    handleFormValid(formik.dirty, formik.isValid, formik.values)
  }, [formik.values, formik.isValid])

  const handleFormValid = (dirty: boolean, isValid: boolean, values: { password: string; confirmedPassword: string }) => {
    if ((dirty || !Object.values(values).includes('')) && isValid) {
      updatePassword(values.password)
      updateGlobalStepData(true)
    } else if (!isValid) {
      updateGlobalStepData(false)
    }
  }

  const updateGlobalStepData = (isComplete: boolean) => {
    const updatedSteps = steps.map((step) => {
      if (step.id === currenStep.id) {
        return { ...currenStep, stepComplete: isComplete }
      } else {
        return step
      }
    })
    const step = { ...currenStep, stepComplete: isComplete }
    updateStepComplete({ current: step, steps: updatedSteps })
  }
  return (
    <StepLayoutWrapper title={currenStep.title}>
      <FormWrapper>
        <div className='flow-inputs-wrapper'>
          <CustomFormInput inputSize='80%' inputType={viewPassword ? 'text' : 'password'} name={'password'} value={formik.values.password} handleChange={formik.handleChange} error={formik.touched.password && formik.errors.password ? formik.errors.password : ''} label='Password' onBlur={formik.handleBlur} />
          <div
            className='view-psswrd-btn'
            onClick={() => {
              setViewPassword(!viewPassword)
            }}
          >
            {viewPassword ? <VisibilityOffIcon style={{ color: '#fff', fontSize: '30px' }} /> : <RemoveRedEyeIcon style={{ color: '#fff', fontSize: '30px' }} />}
          </div>
          <CustomFormInput inputSize='80%' inputType={viewPassword ? 'text' : 'password'} name={'confirmedPassword'} value={formik.values.confirmedPassword} handleChange={formik.handleChange} error={formik.touched.confirmedPassword && formik.errors.confirmedPassword ? formik.errors.confirmedPassword : ''} label='Confirm Password' onBlur={formik.handleBlur} />
        </div>
      </FormWrapper>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
        <Typography variant='h6' align='center' style={{ textDecoration: 'underline' }}>
          Password Rules
        </Typography>
        <ul style={{ width: '80%', listStyle: 'none', display: 'flex', flexFlow: 'row wrap', alignItems: 'center', margin: 0 }}>
          <li style={{ width: '50%', display: 'flex', justifyContent: 'baseline' }}>
            Between 8 - 15 characters [{formik.getFieldProps('password').value.length}] {passwordRules.length && <CheckIcon style={{ color: '#8dc63f' }} />}
          </li>
          <li style={{ width: '50%', display: 'flex', justifyContent: 'baseline' }}>Lower Case Characters {passwordRules.lowerCase && <CheckIcon style={{ color: '#8dc63f' }} />}</li>
          <li style={{ width: '50%', display: 'flex', justifyContent: 'baseline' }}>Upper Case Characters {passwordRules.upperCase && <CheckIcon style={{ color: '#8dc63f' }} />}</li>
          <li style={{ width: '50%', display: 'flex', justifyContent: 'baseline' }}>Number {passwordRules.numbers && <CheckIcon style={{ color: '#8dc63f' }} />}</li>
          <li style={{ width: '50%', display: 'flex', justifyContent: 'baseline' }}>Spectial Characters: #*&!$%?@ {passwordRules.specialChars && <CheckIcon style={{ color: '#8dc63f' }} />}</li>
        </ul>
      </div>
    </StepLayoutWrapper>
  )
}

export default Password

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  .flow-inputs-wrapper {
    display: flex;
    justify-content: space-around;
    margin: 15px 0;
  }
  .view-psswrd-btn {
    width: 40px;
    height: 40px;
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: dodgerblue;
    padding: 2%;
  }
`
