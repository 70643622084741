import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useSignupContextState, useSignupContextActions } from '../useSignupContext'
import { IPersonalInfo } from './config'
import * as Yup from 'yup'
import { useFormik } from 'formik'

import CustomFormInput from './components/CustomFormInput'
import StepLayoutWrapper from './components/StepLayoutWrapper'

const PersonalInfo = () => {
  const { personalInfo, currenStep, steps } = useSignupContextState()
  const { updatePersonalInfo, updateStepComplete } = useSignupContextActions()

  const initialValues: IPersonalInfo = personalInfo.userInfo ? personalInfo.userInfo : { firstName: '', lastName: '', email: '', phone: '' }
  const validationSchema = Yup.object({ firstName: Yup.string().max(15, 'Must be 15 characters or less').required('Required'), lastName: Yup.string().max(20, 'Must be 20 characters or less').required('Required'), email: Yup.string().email('Invalid email address').required('Required'), phone: Yup.string().required('Required') })

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      console.log(values)
    },
  })

  useEffect(() => {
    handleFormValid(formik.dirty, formik.isValid, formik.values)
  }, [formik.values, formik.isValid])

  const handleFormValid = (dirty: boolean, isValid: boolean, values: IPersonalInfo) => {
    if ((dirty || !Object.values(values).includes('')) && isValid) {
      updatePersonalInfo(values)
      updateGlobalStepData(true)
    } else if (!isValid) {
      updateGlobalStepData(false)
    }
  }

  const updateGlobalStepData = (isComplete: boolean) => {
    const updatedSteps = steps.map((step) => {
      if (step.id === currenStep.id) {
        return { ...currenStep, stepComplete: isComplete }
      } else {
        return step
      }
    })
    const step = { ...currenStep, stepComplete: isComplete }
    updateStepComplete({ current: step, steps: updatedSteps })
  }

  return (
    <StepLayoutWrapper title={currenStep.title}>
      <FormWrapper>
        <div className='flow-inputs-wrapper'>
          <CustomFormInput name={'firstName'} value={formik.values.firstName} handleChange={formik.handleChange} error={formik.touched.firstName && formik.errors.firstName ? formik.errors.firstName : ''} label='First Name' onBlur={formik.handleBlur} />
          <CustomFormInput name={'lastName'} value={formik.values.lastName} handleChange={formik.handleChange} error={formik.touched.lastName && formik.errors.lastName ? formik.errors.lastName : ''} label='Last Name' onBlur={formik.handleBlur} />
        </div>
        <div className='flow-inputs-wrapper'>
          <CustomFormInput name={'email'} value={formik.values.email} handleChange={formik.handleChange} error={formik.touched.email && formik.errors.email ? formik.errors.email : ''} label='Email' onBlur={formik.handleBlur} />
          <CustomFormInput name={'phone'} value={formik.values.phone} handleChange={formik.handleChange} error={formik.touched.phone && formik.errors.phone ? formik.errors.phone : ''} label='Phone' onBlur={formik.handleBlur} />
        </div>
      </FormWrapper>
    </StepLayoutWrapper>
  )
}

export default PersonalInfo

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  .flow-inputs-wrapper {
    display: flex;
    justify-content: space-around;
    margin: 15px 0;
  }
`
