import { configureStore } from '@reduxjs/toolkit'
import userReducer from './features/user/userSlice'
import clientReducer from './features/clients/clientsSlice'
import projectSlice from './features/project/projectSlice'
// ...

export const store = configureStore({
  reducer: {
    user: userReducer,
    clients: clientReducer,
    project: projectSlice
  },
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
