import React, { useState, useEffect } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../hooks/useRedux'
import styled from 'styled-components'
import { trackerService } from '../../services'
import IProject from '../../models/interfaces/project/IProject'
import { updateProjectInfo } from '../../store/features/project/projectSlice'
import { status as statusData } from '../../common/data/Status'
import toast from 'react-hot-toast'

import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Paper from '@mui/material/Paper'
import Divider from '@mui/material/Divider'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ProjectDetailTable from '../../components/project-detail/ProjectDetailTable'
import ModeEditIcon from '@mui/icons-material/ModeEdit'
import SaveIcon from '@mui/icons-material/Save'
import CancelIcon from '@mui/icons-material/Cancel'
import IUpdateProjectReq from '../../models/interfaces/project/IUpdateProjectReq'
import { displaySysTimeToReadable, handleTruncateTimeFromSysDate } from '../../common/globalHelperFunctions'
import Form from '../../components/form/Form'
import ProjectContainer from './sections/ProjectContainer'

import { intialValues, validationSchema } from './config'

const ProjectDetail = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { project: stateProject } = useAppSelector((state) => state.project)
  const { clientId, projectId } = useParams<{ clientId: string; projectId: string }>()
  const [project, setProject] = useState<IProject | null>(null)
  const [editProject, setEditProject] = useState<boolean>(false)

  const [title, setTitle] = useState<string>('')
  const [description, setDescription] = useState<string>('')
  const [status, setStatus] = useState<string>('')
  const [startDate, setStartDate] = useState<string | null>(null)
  const [endDate, setEndDate] = useState<string | null>(null)
  const [requestor, setRequestor] = useState<string>('')
  const [isBilled, setIsBilled] = useState<boolean>(false)
  const [isPaid, setIsPaid] = useState<boolean>(false)
  const [notes, setNotes] = useState<string>('')

  useEffect(() => {
    projectId &&
      (async () => {
        const res = await trackerService.getProject(projectId)
        dispatch(updateProjectInfo(res.data))
      })()
  }, [])

  useEffect(() => {
    stateProject && setProject(stateProject)
  }, [stateProject])

  const updateSelectedProject = async () => {
    if (!title || !description || !status) {
      return
    }
    const reqData: IUpdateProjectReq = {
      title: title,
      description: description,
      status: status,
      startDate: startDate || null,
      endDate: endDate || null,
      requestor: requestor || '',
      notes: notes || '',
      isBilled: isBilled,
      isPaid: isPaid,
    }

    const updateRes = await trackerService.updateProject(projectId || '', reqData)

    if (updateRes.success) {
      toast.success('Project Update Successful.')
      resetFields()
      setEditProject(false)
      const res = await trackerService.getProject(projectId || '')
      dispatch(updateProjectInfo(res.data))
    } else if (!updateRes.success) {
      toast.success('Could Not Update Project.')
    }
  }

  const handleEditProject = () => {
    const isEditing = !editProject
    setEditProject(isEditing)
    if (isEditing) {
      setTitle(project?.title ?? '')
      setDescription(project?.description ?? '')
      setStatus(project?.status ?? '')
      setStartDate(handleTruncateTimeFromSysDate(project?.startDate || null) ?? '')
      setEndDate(handleTruncateTimeFromSysDate(project?.endDate || null) ?? '')
      setRequestor(project?.requestor ?? '')
      setIsBilled(project?.isBilled ?? false)
      setIsPaid(project?.isPaid ?? false)
      setNotes(project?.notes ?? '')
    }
  }

  const resetFields = () => {
    setTitle('')
    setDescription('')
    setStatus('')
    setStartDate(null)
    setEndDate(null)
    setRequestor('')
    setIsBilled(false)
    setIsPaid(false)
    setNotes('')
  }

  return (
    <ProjectDetailWrapper>
      <div className='heading-wrapper'>
        <Typography variant='h3' className='title'>
          Project Detail
        </Typography>
        <Button
          style={{ height: '40px' }}
          variant='contained'
          onClick={() => {
            navigate(`/client/${clientId}`)
          }}
        >
          <ArrowBackIcon />
        </Button>
      </div>
      <Divider />
      {/* <Form initialvalues={intialValues} validationSchema={validationSchema}>
        <ProjectContainer />
      </Form> */}
      <div className='content-wrapper'>
        <Paper elevation={4} style={{ width: '100%', padding: '2%', borderRadius: '18px' }}>
          <div className='edit-btn'>
            <Button className='top-edit-btn' style={{ color: editProject ? '#d32f2f' : '' }} onClick={() => handleEditProject()}>
              {editProject ? (
                <>
                  Cancel
                  <CancelIcon />
                </>
              ) : (
                <>
                  Edit
                  <ModeEditIcon />
                </>
              )}
            </Button>
            {editProject && (
              <Button className='top-edit-btn' onClick={() => updateSelectedProject()}>
                Save
                <SaveIcon />
              </Button>
            )}
          </div>
          <div className='row'>
            {!editProject ? (
              <div className='col round-border'>
                <Typography variant='h5'>Project Info</Typography>

                <div className='content-lbl-wrapper'>
                  <p className='label'>Title:</p>
                  <p className='titl-txt'>{project?.title}</p>
                </div>
                <div className='content-lbl-wrapper'>
                  <p className='label'>Description:</p>
                  <p className='titl-txt'>{project?.description}</p>
                </div>
                <div className='content-lbl-wrapper'>
                  <p className='label'>Status:</p>
                  <p className='titl-txt'>{project?.status}</p>
                </div>
                <div className='content-lbl-wrapper'>
                  <p className='label'>Start Date:</p>
                  <p className='titl-txt'>{displaySysTimeToReadable(project?.startDate || null)}</p>
                </div>
                <div className='content-lbl-wrapper'>
                  <p className='label'>End Date:</p>
                  <p className='titl-txt'>{displaySysTimeToReadable(project?.endDate || null)}</p>
                </div>
                <div className='content-lbl-wrapper'>
                  <p className='label'>Requestor:</p>
                  <p className='titl-txt'>{project?.requestor}</p>
                </div>
              </div>
            ) : (
              <div className='col round-border'>
                <Typography variant='h5'>Edit Project Info</Typography>

                <div className='content-lbl-wrapper'>
                  <p className='label'>Title:</p>
                  <input
                    className='app-form-input'
                    value={title}
                    onChange={(e) => {
                      setTitle(e.target.value)
                    }}
                  />
                </div>
                <div className='content-lbl-wrapper'>
                  <p className='label'>Description:</p>
                  <input
                    className='app-form-input'
                    value={description}
                    onChange={(e) => {
                      setDescription(e.target.value)
                    }}
                  />
                </div>
                <div className='content-lbl-wrapper'>
                  <p className='label'>Status:</p>
                  <select
                    className='app-form-input'
                    value={status}
                    onChange={(e) => {
                      setStatus(e.target.value)
                    }}
                  >
                    <option selected disabled>
                      *Select Status
                    </option>
                    {statusData.map((_status, index: number) => {
                      return (
                        <option key={index} value={_status}>
                          {_status}
                        </option>
                      )
                    })}
                  </select>
                </div>
                <div className='content-lbl-wrapper'>
                  <p className='label'>Start Date:</p>
                  <input
                    className='app-form-input'
                    type={'date'}
                    value={startDate || ''}
                    onChange={(e) => {
                      setStartDate(e.target.value)
                    }}
                  />
                </div>
                <div className='content-lbl-wrapper'>
                  <p className='label'>End Date:</p>
                  <input
                    className='app-form-input'
                    type={'date'}
                    value={endDate || ''}
                    onChange={(e) => {
                      setEndDate(e.target.value)
                    }}
                  />
                </div>
                <div className='content-lbl-wrapper'>
                  <p className='label'>Requestor:</p>
                  <input
                    className='app-form-input'
                    value={requestor}
                    onChange={(e) => {
                      setRequestor(e.target.value)
                    }}
                  />
                </div>
              </div>
            )}
            {!editProject ? (
              <div className='col round-border'>
                <Typography variant='h5'>Project Billing</Typography>
                <div className='content-lbl-wrapper'>
                  <p className='label'>Billed:</p>
                  <p className='titl-txt'>{project?.isBilled ? 'Yes' : 'No'}</p>
                </div>
                <div className='content-lbl-wrapper'>
                  <p className='label'>Paid:</p>
                  <p className='titl-txt'>{project?.isPaid ? 'Yes' : 'No'}</p>
                </div>
                {project?.invoiceId && (
                  <div className='content-lbl-wrapper'>
                    <p className='label'>Invoice Created:</p>
                    <Link to={`/invoice/${project?.invoiceId}`}>
                      <p>View Invoice</p>
                    </Link>
                  </div>
                )}
              </div>
            ) : (
              <div className='col round-border'>
                <Typography variant='h5'>Project Billing</Typography>
                <div className='content-lbl-wrapper'>
                  <p className='label'>Billed:</p>
                  <input
                    type='checkbox'
                    checked={isBilled}
                    onChange={(e) => {
                      setIsBilled(e.target.checked)
                    }}
                  />
                </div>
                <div className='content-lbl-wrapper'>
                  <p className='label'>Paid:</p>
                  <input
                    type='checkbox'
                    checked={isPaid}
                    onChange={(e) => {
                      setIsPaid(e.target.checked)
                    }}
                  />
                </div>
              </div>
            )}
          </div>

          <div className='row'>
            {!editProject ? (
              <div className='col round-border'>
                <Typography variant='h5'>Notes</Typography>
                <div className='content-lbl-wrapper'>
                  <p className='label'>Notes:</p>
                  <p className='titl-txt'>{project?.notes}</p>
                </div>
              </div>
            ) : (
              <div className='col round-border'>
                <Typography variant='h5'>Edit Notes</Typography>
                <div className='content-lbl-wrapper'>
                  <p className='label'>Notes:</p>
                  <textarea
                    className='app-form-input'
                    rows={5}
                    value={notes}
                    onChange={(e) => {
                      setNotes(e.target.value)
                    }}
                  />
                </div>
              </div>
            )}
          </div>
        </Paper>
      </div>
      <ProjectDetailTable clientId={clientId || ''} />
    </ProjectDetailWrapper>
  )
}

export default ProjectDetail

const ProjectDetailWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2%;
  width: 100%;
  .title {
    width: 90%;
  }

  .heading-wrapper {
    display: flex;
    width: 100%;
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .round-border {
    border: 2px solid rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    padding: 1%;
    margin: 1%;
  }

  .content-wrapper {
    margin-top: 3%;
    width: 100%;
    display: flex;
    .row {
      display: flex;
      width: 100%;
      .col {
        width: 100%;
        .content-lbl-wrapper {
          display: flex;

          .label {
            width: 25%;
            font-weight: 600;
          }
        }
      }
    }
  }

  .edit-btn {
    width: 100%;
    display: flex;
    justify-content: flex-end;

    .top-edit-btn {
      margin: 0 10px;
    }
  }

  .table-wrapper {
    margin-top: 2%;
  }
`
