import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useSignupContextState, useSignupContextActions } from '../useSignupContext'
import { ICompanyInfo, IPersonalInfo } from './config'
import * as Yup from 'yup'
import { useFormik } from 'formik'

import CustomFormInput from './components/CustomFormInput'
import StepLayoutWrapper from './components/StepLayoutWrapper'

const CompanyInfo = () => {
  const { companyInfo, currenStep, steps } = useSignupContextState()
  const { updateCompanyInfo, updateStepComplete } = useSignupContextActions()

  const initialValues: ICompanyInfo = companyInfo.company ? companyInfo.company : { companyName: '', companyEmail: '', companyPhone: '' }
  const validationSchema = Yup.object({ companyName: Yup.string().required('Required'), companyEmail: Yup.string().email('Invalid email address').required('Required'), companyPhone: Yup.string().required('Required') })

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      console.log(values)
    },
  })

  useEffect(() => {
    handleFormValid(formik.dirty, formik.isValid, formik.values)
  }, [formik.values, formik.isValid])

  const handleFormValid = (dirty: boolean, isValid: boolean, values: ICompanyInfo) => {
    if ((dirty || !Object.values(values).includes('')) && isValid) {
      updateCompanyInfo(values)
      updateGlobalStepData(true)
    } else if (!isValid) {
      updateGlobalStepData(false)
    }
  }

  const updateGlobalStepData = (isComplete: boolean) => {
    const updatedSteps = steps.map((step) => {
      if (step.id === currenStep.id) {
        return { ...currenStep, stepComplete: isComplete }
      } else {
        return step
      }
    })
    const step = { ...currenStep, stepComplete: isComplete }
    updateStepComplete({ current: step, steps: updatedSteps })
  }

  return (
    <StepLayoutWrapper title={currenStep.title}>
      <FormWrapper>
        <div className='flow-inputs-wrapper'>
          <CustomFormInput name={'companyName'} value={formik.values.companyName} handleChange={formik.handleChange} error={formik.touched.companyName && formik.errors.companyName ? formik.errors.companyName : ''} label='Company Name' onBlur={formik.handleBlur} />
        </div>
        <div className='flow-inputs-wrapper'>
          <CustomFormInput name={'companyEmail'} value={formik.values.companyEmail} handleChange={formik.handleChange} error={formik.touched.companyEmail && formik.errors.companyEmail ? formik.errors.companyEmail : ''} label='Email' onBlur={formik.handleBlur} />
          <CustomFormInput name={'companyPhone'} value={formik.values.companyPhone} handleChange={formik.handleChange} error={formik.touched.companyPhone && formik.errors.companyPhone ? formik.errors.companyPhone : ''} label='Phone' onBlur={formik.handleBlur} />
        </div>
      </FormWrapper>
    </StepLayoutWrapper>
  )
}

export default CompanyInfo

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  .flow-inputs-wrapper {
    display: flex;
    justify-content: space-around;
    margin: 15px 0;
  }
`
