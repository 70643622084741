import React, { useState } from 'react'
import styled from 'styled-components'
import INavigationBarLink from '../../../models/interfaces/settings/INavigationBarLink'
import { Link } from 'react-router-dom'

interface INavigationBarProps {
  navigationLinks: Array<INavigationBarLink>
}

const NavigationBar: React.FC<INavigationBarProps> = ({ navigationLinks }) => {
  const [activeLink, setActiveLink] = useState<number>(1)

  const setLinkActive = (id: number) => {
    setActiveLink(id)
  }
  return (
    <NavigationBarContainer>
      <ul className='nav-list-wrapper'>
        {navigationLinks.map((link) => {
          return (
            <Link
              key={link.id}
              to={link.linkTo}
              onClick={() => {
                setLinkActive(link.id)
              }}
            >
              <li className={`nav-btn ${activeLink === link.id && 'active-link'}`}>{link.linkName}</li>
            </Link>
          )
        })}
      </ul>
    </NavigationBarContainer>
  )
}

export default NavigationBar

const NavigationBarContainer = styled.div`
  .nav-list-wrapper {
    display: flex;
    width: 50%;
    list-style: none;
    a {
      text-decoration: none;
      color: #000;
      margin: 0 1px;
      li {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 110px;
        height: 45px;
        border: 2px solid rgba(0, 0, 0, 0.05);
      }
    }
  }
  .active-link {
    background-color: var(--app-green);
    border-radius: 12px 12px 0 0;
    color: #fff;
    transition: all 0.2s ease-in-out;
    box-shadow: 0px -5px 12px 2px rgba(0, 0, 0, 0.2);
    border: 2px solid rgba(0, 0, 0, 0.8);
    animation: rubberBand 0.5s;
    transform-origin: center;
  }

  @keyframes rubberBand {
    from {
      transform: scale3d(1, 1, 1);
    }
    /* 
    30% {
      transform: scale3d(1.25, 0.75, 1);
    }

    40% {
      transform: scale3d(0.75, 1.25, 1);
    } */

    50% {
      transform: scale3d(1.15, 0.85, 1);
    }

    65% {
      transform: scale3d(0.95, 1.05, 1);
    }

    75% {
      transform: scale3d(1.05, 0.95, 1);
    }

    to {
      transform: scale3d(1, 1, 1);
    }
  }
`
