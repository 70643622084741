import React, { useEffect } from 'react'
import { useClientStore } from './hooks/useClientStore'
import { useNavigate } from 'react-router-dom'
import { useClientContextActions, useClientContextState } from './hooks/clientContext'

import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import AddIcon from '@mui/icons-material/Add'
import AddClientModal from './AddClientModal'
import { useAppSelector } from '../../hooks/useRedux'

const ClientsTable = () => {
  const navigate = useNavigate()
  // const { clients } = useClientStore()
  const { userInfo } = useAppSelector((state) => state.user)
  const { clients } = useClientContextState()
  const { updateDisplayAddClientModal, fetchClients } = useClientContextActions()

  useEffect(() => {
    userInfo && fetchClients(userInfo?._id)
  }, [userInfo])

  return (
    <div style={{ marginTop: '10%' }}>
      <TableContainer component={Paper} elevation={5} style={{ borderRadius: '18px' }}>
        <div style={{ padding: '2%', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', borderBottom: '2px solid rgba(0,0,0,0.1)', backgroundColor: 'var(--app-theme-color-primary)' }}>
          <div style={{ width: '90%' }}>
            <Typography variant='h5'>Clients List</Typography>
          </div>
          <div style={{ width: '8%' }}>
            <Button
              variant='outlined'
              style={{ width: '35px', height: '35px' }}
              title='Add New Client'
              onClick={() => {
                updateDisplayAddClientModal(true)
              }}
            >
              <AddIcon style={{ width: '30px' }} />
            </Button>
          </div>
        </div>
        <Table sx={{ minWidth: 650 }} aria-label='simple table'>
          <TableHead>
            <TableRow>
              <TableCell>Client Name</TableCell>
              <TableCell align='right'>Email</TableCell>
              <TableCell align='right'>phone</TableCell>
              <TableCell align='right'>View</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {clients && clients.length > 0 ? (
              clients.map((client) => (
                <TableRow key={client._id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component='th' scope='row'>
                    {client.businessName}
                  </TableCell>
                  <TableCell align='right'>{client.email}</TableCell>
                  <TableCell align='right'>{client.phone}</TableCell>
                  <TableCell align='right'>
                    <Button
                      variant='outlined'
                      size='small'
                      onClick={() => {
                        navigate(`/client/${client._id}`)
                      }}
                    >
                      details
                    </Button>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableCell>
                <Typography variant='h5'>No Client Data</Typography>
              </TableCell>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <AddClientModal />
    </div>
  )
}

export default ClientsTable
function useClientContext() {
  throw new Error('Function not implemented.')
}
