import React, { useEffect, useState } from 'react'
import { useAppSelector } from '../../hooks/useRedux'
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js'
import { Bar } from 'react-chartjs-2'
import { trackerService } from '../../services'
import IInvoiceReportReq from '../../models/interfaces/invoices/IInoviceReportReq'
import IClient from '../../models/interfaces/clients/IClient'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

export const allClientsOptions = {
  plugins: {
    title: {
      display: true,
      text: 'Billed / Collected for all Clients',
    },
  },
  responsive: true,
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
    },
  },
}

const allClientslabels = ['Totals (Billed / Collected)']

export const clientOptions = {
  plugins: {
    title: {
      display: true,
      text: 'Billed / Collected for Client',
    },
  },
  responsive: true,
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
    },
  },
}

const clientlabels = ['Totals (Billed / Collected) for Client']

enum ESelectFiltersOptions {
  ALL_CLIENTS = 'All Clients',
  BY_CLIENT = 'By Client',
}

const Income = () => {
  const [options, setOptions] = useState<any>(allClientsOptions)
  const { userInfo } = useAppSelector((state) => state.user)
  const [currentFilter, setCurrentFilter] = useState<ESelectFiltersOptions>(ESelectFiltersOptions.ALL_CLIENTS)
  const [currentClient, setCurrentClient] = useState<IClient | null>(null)
  const [clients, setClients] = useState<Array<IClient>>([])
  const [calculating, setCalculating] = useState(false)

  const setupChartOptions = (labelType: any, amountOne: any, amountTwo: any) => {
    return {
      labels: labelType,
      datasets: [
        {
          label: 'Billed',
          data: [amountOne],
          backgroundColor: 'rgb(255, 99, 132)',
        },
        {
          label: 'Collected',
          data: [amountTwo],
          backgroundColor: 'rgb(75, 192, 192)',
        },
      ],
    }
  }
  const [data, setData] = useState<any>(setupChartOptions(allClientslabels, 0, 0))

  useEffect(() => {
    reportInovices()
    getClients()
  }, [])

  const reportInovices = async () => {
    const reqDate: IInvoiceReportReq = {
      userId: userInfo?._id ?? '',
    }
    const res = await trackerService.reportInvoices(reqDate)
    if (res.success && res.data) {
      setData(setupChartOptions(allClientslabels, res.data.billedAmount, res.data.collectedAmount))
    }
    setCalculating(true)
  }

  const getClients = async () => {
    const res = await trackerService.getClients(userInfo?._id ?? '')
    if (res.success) {
      setClients(res.data)
    }
  }

  const updateFilters = async (filterType: string, value: any) => {
    if (filterType === 'TopFilter') {
      setCurrentFilter(value)
    } else if (filterType === 'ClientFilter') {
      setCurrentClient(JSON.parse(value))
    }

    let req: IInvoiceReportReq | null = null
    if (filterType === 'TopFilter' && value === ESelectFiltersOptions.ALL_CLIENTS) {
      req = {
        userId: userInfo?._id ?? '',
      }

      const res = await trackerService.reportInvoices(req)
      setOptions(allClientsOptions)
      setData(setupChartOptions(allClientslabels, res.data.billedAmount, res.data.collectedAmount))
    } else if (filterType === 'ClientFilter' && currentFilter === ESelectFiltersOptions.BY_CLIENT && JSON.parse(value)) {
      req = {
        userId: userInfo?._id ?? '',
        clientId: JSON.parse(value)._id,
      }

      const res = await trackerService.reportInvoices(req)
      setOptions(clientOptions)
      setData(setupChartOptions(clientlabels, res.data.billedAmount, res.data.collectedAmount))
    }
  }

  return (
    <div style={{ marginTop: '5%' }}>
      <h1>Income Reports</h1>
      {calculating ? (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
          <div style={{ display: 'flex', width: '100%', alignItems: 'flex-start' }}>
            <select
              value={currentFilter}
              onChange={(e) => {
                updateFilters('TopFilter', e.target.value)
              }}
            >
              <option value={ESelectFiltersOptions.ALL_CLIENTS}>{ESelectFiltersOptions.ALL_CLIENTS}</option>
              <option value={ESelectFiltersOptions.BY_CLIENT}>{ESelectFiltersOptions.BY_CLIENT}</option>
            </select>

            {currentFilter === ESelectFiltersOptions.BY_CLIENT && (
              <select
                value={currentClient ? JSON.stringify(currentClient) : ''}
                onChange={(e) => {
                  updateFilters('ClientFilter', e.target.value)
                }}
              >
                <option value='' disabled selected>
                  Select A Client
                </option>
                {clients.length > 0 &&
                  clients.map((client) => {
                    return (
                      <option key={client._id} value={JSON.stringify(client)}>
                        {client.businessName}
                      </option>
                    )
                  })}
              </select>
            )}
          </div>

          {currentFilter === ESelectFiltersOptions.BY_CLIENT && currentClient && <h3>Reporting for {currentClient.businessName}</h3>}
          <div style={{ width: '70%', height: '500px' }}>
            {' '}
            <Bar options={options} data={data} />
          </div>
        </div>
      ) : (
        <h2>LOADING...</h2>
      )}
    </div>
  )
}

export default Income
