import { useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import styled from 'styled-components'
import { trackerService } from '../../services/index'
import { useAppDispatch } from '../../hooks/useRedux'
import { useNavigate } from 'react-router-dom'
import toast from 'react-hot-toast'

import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import LoginIcon from '@mui/icons-material/Login'
import CircularProgress from '@mui/material/CircularProgress'
import { Link } from 'react-router-dom'
import { updateUserInfo, updateUserStatus } from '../../store/features/user/userSlice'
import { EStorageKeys, removeStorageValue, setStorageValue } from '../../services/storage/app-storage-api'

interface IFormValues {
  email: string
  password: string
}

const Login = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [loading, setLoading] = useState<boolean>(false)
  const [errorMsg, setErrorMsg] = useState<string | null>(null)

  const submitForm = async (values: any) => {
    setLoading(true)
    try {
      const res = await trackerService.signin(values)
      setErrorMsg(res && res.messages[0])
      if (res.data) {
        toast.success('Login Successful')
        setToLocalStorage(res.data.accessToken, res.data.userId)
        trackerService.updateToken(`Bearer ${res.data.accessToken}`)
        trackerService.updateUserId(res.data.userId)
        resetForm()
        setLoading(false)
        setTimeout(async () => {
          const userInfoRes = await trackerService.getUserInfo(res.data.userId)
          dispatch(updateUserInfo(userInfoRes.data))
          dispatch(updateUserStatus(true))
          navigate('/clients')
        }, 500)
      } else {
        setLoading(false)
      }
    } catch (err: any) {
      console.error(err)
      setLoading(false)
    }
  }

  const resetForm = () => {
    formik.setFieldValue('email', '')
    formik.setFieldValue('password', '')
  }

  const setToLocalStorage = (accessToken: string, userId: string) => {
    // clear old data
    removeStorageValue(EStorageKeys.ACCESS_TOKEN)
    removeStorageValue(EStorageKeys.USER_ID)
    // set new payload data
    setStorageValue(EStorageKeys.ACCESS_TOKEN, accessToken)
    setStorageValue(EStorageKeys.USER_ID, userId)
  }

  const validationSchema = Yup.object({
    email: Yup.string().email('Invalid Email Address').required('Required'),
    password: Yup.string().max(15, '15 Character Max').min(8, '8 Character Minimum').required('Required'),
  })

  const intialValues: IFormValues = {
    email: '',
    password: '',
  }

  const formik = useFormik({
    initialValues: intialValues,
    validationSchema,
    onSubmit: submitForm,
  })

  return (
    <SingupWrapper>
      <Box
        className='signin-form'
        onSubmit={(e) => {
          e.preventDefault()
          formik.handleSubmit()
        }}
        component='form'
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          marginTop: '3%',
          '& .MuiTextField-root': { m: 1, width: '25ch' },
        }}
        noValidate
        autoComplete='off'
      >
        <Paper elevation={10} style={{ maxWidth: '600px', width: '100%', display: 'flex', alignItems: 'center', flexDirection: 'column', padding: '0 0 4% 0', position: 'relative', zIndex: 1, overflow: 'hidden' }}>
          <Typography variant='h2' style={{ marginBottom: '3%', backgroundColor: 'var(--app-theme-color-tertiary)', width: '100%', textAlign: 'center', padding: '2%' }}>
            LOGIN
          </Typography>
          <InputWrapper>
            <TextField error={formik.errors.email ? true : false} id='email' label='Email' defaultValue='Email' {...formik.getFieldProps('email')} />
            {formik.touched.email && formik.errors.email ? <p className='error-txt'>{formik.errors.email}</p> : null}
          </InputWrapper>
          <InputWrapper>
            <TextField type='password' error={formik.errors.password ? true : false} id='password' label='Password' defaultValue='Password' {...formik.getFieldProps('password')} />
            {formik.touched.password && formik.errors.password ? <p className='error-txt'>{formik.errors.password}</p> : null}
          </InputWrapper>
          <Button variant='contained' style={{ width: '225px', height: '45px' }} type='submit' disabled={!formik.isValid || !formik.dirty}>
            Login
            <LoginIcon />
          </Button>
          {loading && <CircularProgress />}
          {errorMsg && <p className='error-txt'>{errorMsg}</p>}
          <LinksWrapper>
            <div className='links-btn-wrapper'>
              <p>Don't have and account?</p>
              <Link to='/signup' className='app-links btn-link'>
                Signup
              </Link>
            </div>
            <div className='links-btn-wrapper'>
              <p>Forgot Password?</p>
              <Link to='reset-password' className='app-links'>
                Reset Password
              </Link>
            </div>
          </LinksWrapper>
          <div className='blog-img-login'></div>
          <div className='blog-img-login-two'></div>
        </Paper>
      </Box>
    </SingupWrapper>
  )
}

export default Login

const SingupWrapper = styled.div`
  .blog-img-login {
    background-image: url('/assets/common/graph-green.png');
    width: 150%;
    height: 100%;
    background-size: cover;
    background-position: center;
    position: absolute;
    right: 0px;
    bottom: -85px;
    z-index: -1;
    opacity: 0.3;
  }
  .blog-img-login-two {
    background-image: url('/assets/common/graph.png');
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    position: absolute;
    right: -200px;
    bottom: -300px;
    z-index: -1;
    opacity: 0.3;
    transform: rotate(140deg);
  }

  .error-txt {
    color: #d32f2f;
    padding: 0;
    margin: 0;
  }

  .signin-form {
    position: relative;
    @keyframes slideup {
      0% {
        top: 800px;
        opacity: 0;
      }

      100% {
        top: 0px;
        opacity: 1;
      }
    }
    animation-name: slideup;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
  }
`

const InputWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: 2% 0;
`

const LinksWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 80%;
  margin-top: 2%;
  .app-links {
    text-decoration: none;
    color: var(--app-theme-color-four);
  }
  .btn-link {
    background-color: dodgerblue;
    padding: 10px 30px;
    border-radius: 12px;
    color: #fff;
  }

  .links-btn-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 40%;
  }
`
