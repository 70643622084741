import { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'
import PDFInvoice from '../../components/global/pdf/PDFInvoice'
import ISendInvoiceReq from '../../models/interfaces/invoices/ISendInvoiceReq'
import IInvoicePDF from '../../models/interfaces/pdfs/IInvoicePDF'
import { trackerService } from '../../services'

import { Button, TextField } from '@mui/material'
import AppModal from '../../components/global/AppModal'
import toast from 'react-hot-toast'

const templateMsgs = [
  { id: 1, title: 'Thank You', message: 'We thank you for your business and look forward to serving you in the future' },
  { id: 2, title: 'Greetings', message: 'We appreciate your business, should you require anything please let us know and we will respond promptly' },
  { id: 3, title: 'Give Me Money', message: 'Give me my money!' },
]

enum EMessageType {
  CUSTOM = 'CUSTOM',
  TEMPLATE = 'TEMPLATE',
}

const InvoiceDetail = () => {
  const { invoiceId } = useParams<{ invoiceId: string }>()
  const [invoice, setInvoice] = useState<IInvoicePDF>()
  const [showSentModal, setShowSentModal] = useState<boolean>(false)
  const [dupSendMsg, setSetDupSendMsg] = useState<string>('')
  const [showSendModal, setShowSendModal] = useState<boolean>(false)
  const [messageTypeChoice, setMessageTypeChoice] = useState<EMessageType | null>(EMessageType.CUSTOM)
  const [emailMessage, setEmailMessage] = useState<string>('')
  const [pdfFile, setPdfFile] = useState<any | null>(null)
  const [recipient, setRecipient] = useState<string>('')
  const [contacts, setContacts] = useState<Array<string>>([])

  useEffect(() => {
    invoiceId &&
      (async () => {
        const res = await trackerService.getInvoice(invoiceId)
        setInvoice(res.data)
      })()
  }, [])

  useEffect(() => {
    if (invoice && showSendModal) {
      fetchClientEmail(invoice.clientId)
    }
  }, [showSendModal])

  useEffect(() => {
    if (messageTypeChoice === EMessageType.TEMPLATE && !emailMessage && templateMsgs && templateMsgs.length > 0) {
      setEmailMessage(templateMsgs[0].message)
    }
  }, [messageTypeChoice])

  const fetchClientEmail = async (clientId: string) => {
    try {
      const res = await trackerService.getClient(clientId)
      if (res.success) {
        const contactList: Array<string> = []
        res.data.email && contactList.push(res.data.email)
        res.data.contact && res.data.contact.email && contactList.push(res.data.contact.email)
        setContacts(contactList)
        setRecipient(contactList[0])
      }
    } catch (err: any) {}
  }

  const handleMarkInvoicePaid = async () => {
    try {
      const data: { invoiceId: string; invoiceIdList: Array<string>; updateProjectPayload: { isPaid: boolean } } = {
        invoiceId: invoice?._id ?? '',
        invoiceIdList: invoice?.projectIds ?? [],
        updateProjectPayload: {
          isPaid: true,
        },
      }
      const res = await trackerService.updateInvoicePaidStatus(data)

      if (res.success) {
        const res = await trackerService.getInvoice(invoiceId || '')
        setInvoice(res.data)
      }
    } catch (err: any) {
      console.error(err.message)
    }
  }

  const checkIfPreviouslySent = (): string | boolean => {
    if (!invoice) {
      throw new Error('No Invoice to send.')
    }
    if (invoice.deliveryStatus === 'Mailed' || invoice.deliveryStatus === 'Emailed') {
      return 'Email already sent are you sure you would like to resend?'
    }
    return false
  }

  const sendInvoice = () => {
    const isSentStatus = checkIfPreviouslySent()
    if (typeof isSentStatus === 'string') {
      setSetDupSendMsg(isSentStatus)
      setShowSentModal(true)
    } else {
      setShowSendModal(true)
    }
  }

  const emailInvoice = async () => {
    let req: ISendInvoiceReq | null = null

    const data: ISendInvoiceReq | any = await new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(pdfFile)
      reader.onloadend = async () => {
        try {
          req = {
            invoiceId: invoice?._id || '',
            msgBody: emailMessage,
            file: await reader.result,
            to: recipient,
            templateData: {
              companyName: invoice?.clientInformation.businessName ?? '',
              deliveryDate: new Date(Date.now()).toDateString(),
              customerMessage: emailMessage,
            },
          }
          resolve(req)
        } catch (err) {
          reject(err)
        }
      }
      reader.onerror = (error) => {
        reject(error)
      }
    })

    const res = await trackerService.sendInvoice(data)
    if (res.success) {
      toast.success('Invoice Sent!')
      setShowSendModal(false)
    } else {
      toast.error('Invoice Not Sent.')
    }
  }

  return (
    <InvoiceDetailWrapper>
      <h1 className='title'>Invoice Detail</h1>
      {!invoice?.isPaid && (
        <div className='btn-wrapper'>
          <Button
            variant='contained'
            onClick={() => {
              handleMarkInvoicePaid()
            }}
          >
            Mark As Paid
          </Button>
          <Button variant='contained' onClick={() => sendInvoice()}>
            Email Invoice
          </Button>
        </div>
      )}
      <PDFInvoice invoiceData={invoice ?? null} setPDFFile={setPdfFile} />
      <AppModal
        buttonOneTitle='Continue'
        buttonTwoTitle='Cancel'
        show={showSentModal}
        closeBtnAction={() => {
          setShowSentModal(false)
        }}
        saveBtnAction={() => {
          setShowSentModal(false)
          setShowSendModal(true)
        }}
      >
        <div className='email-invoice-wrapper'>
          <div className='email-invoice-ttl'>Invoice Already Sent</div>
          <div className='email-invoice-body'>
            <div className='message'>{dupSendMsg}</div>
          </div>
        </div>
      </AppModal>
      <AppModal
        buttonOneTitle='Send'
        buttonTwoTitle='Cancel'
        show={showSendModal}
        closeBtnAction={() => {
          setShowSendModal(false)
        }}
        saveBtnAction={() => emailInvoice()}
      >
        <div className='email-invoice-wrapper'>
          <div className='email-invoice-ttl'>Send Invoice</div>
          <div className='email-invoice-body'>
            <div className='message'>
              Please enter a custom message you would like to send with this invoice or choose a template message. (<i>You can create template messages in your settings.</i>)
            </div>
            <div>
              <h5>Email Recipient:</h5>
              <div style={{ display: 'flex', flexDirection: 'column', border: '2px solid rgba(0,0,0,0.07)', padding: '2%', borderRadius: '8px' }}>
                <div className='input-wrapper'>
                  <label htmlFor='msg-choice'>Existing Client Emails</label>
                  <select id='msg-choice' className='custom-selector' onChange={(e) => setRecipient(e.target.value as any)}>
                    {contacts &&
                      contacts.length > 0 &&
                      contacts.map((contact, index) => {
                        return (
                          <option key={index} value={contact}>
                            {contact}
                          </option>
                        )
                      })}
                  </select>
                </div>
                <div className='input-wrapper' style={{ marginTop: 20 }}>
                  <TextField
                    style={{ width: 300 }}
                    type='text'
                    id='recipientEmail'
                    label='Recipient Email'
                    value={recipient}
                    onChange={(e) => {
                      setRecipient(e.target.value)
                    }}
                  />
                </div>
              </div>
            </div>
            {templateMsgs.length > 0 ? (
              <div>
                <h5>Message Body:</h5>
                <div style={{ display: 'flex', justifyContent: 'space-around', flexDirection: messageTypeChoice === EMessageType.TEMPLATE ? 'row' : 'column', border: '2px solid rgba(0,0,0,0.07)', padding: '2%', borderRadius: '8px' }}>
                  <div className='input-wrapper'>
                    <label htmlFor='msg-choice'>Type</label>
                    <select id='msg-choice' className='custom-selector' onChange={(e) => setMessageTypeChoice(e.target.value as any)}>
                      <option value={EMessageType.CUSTOM}>Custom</option>
                      <option value={EMessageType.TEMPLATE}>Template</option>
                    </select>
                  </div>
                  {messageTypeChoice === 'CUSTOM' ? (
                    <div className='input-wrapper'>
                      <label>Custom Message</label>
                      <textarea
                        rows={8}
                        value={emailMessage}
                        onChange={(e) => {
                          setEmailMessage(e.target.value)
                        }}
                      />
                    </div>
                  ) : (
                    <div className='input-wrapper'>
                      <label htmlFor='temp-msg'>Template Message</label>
                      <select id='temp-msg' className='custom-selector' onChange={(e) => setEmailMessage(e.target.value as any)}>
                        {templateMsgs.map((template) => {
                          return (
                            <option key={template.id} value={template.message}>
                              {template.title}
                            </option>
                          )
                        })}
                      </select>
                    </div>
                  )}
                </div>
                {messageTypeChoice === EMessageType.TEMPLATE && emailMessage && (
                  <div className='display-tmplt-msg'>
                    <label>Your Message:</label>
                    <p>{emailMessage}</p>
                  </div>
                )}
              </div>
            ) : (
              <div className='input-wrapper'>
                <label>Custom Message</label>
                <textarea
                  rows={8}
                  value={emailMessage}
                  onChange={(e) => {
                    setEmailMessage(e.target.value)
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </AppModal>
    </InvoiceDetailWrapper>
  )
}

export default InvoiceDetail

const InvoiceDetailWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .title {
    font-size: 40px;
  }

  .btn-wrapper {
    display: flex;
    justify-content: space-evenly;
    width: 70%;
    margin: 2%;
  }

  .email-invoice-wrapper {
    width: 100%;
    .email-invoice-ttl {
      text-align: center;
      border-radius: 18px 18px 0 0;
      height: 60px;
      width: 100%;
      background-color: dodgerblue;
      font-size: 2.1rem;
      color: inherit;
    }
    .email-invoice-body {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 2%;
      .message {
        text-align: center;
        i {
          font-size: 13px;
        }
      }
      .input-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        label {
          font-size: 14px;
          font-weight: 600;
        }
      }
    }
    .display-tmplt-msg {
      margin-top: 5%;
      label {
        font-size: 14px;
        font-weight: 600;
      }
    }
  }

  .custom-selector {
    width: 175px;
    height: 35px;
    border-color: dodgerblue;
    border-radius: 8px;
  }
`
