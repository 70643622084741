import React from 'react'
import Box from '@mui/material/Box'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import { useSignupContextState } from '../useSignupContext'

const CustomStepper = () => {
  const { steps, currenStep } = useSignupContextState()
  return (
    <Box sx={{ width: '100%' }}>
      <Stepper activeStep={currenStep.id - 1} alternativeLabel>
        {steps.map((step) => (
          <Step key={step.id}>
            <StepLabel>{step.title}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  )
}

export default CustomStepper
