import React from 'react'
import InvoiceTable from '../../components/invoices/InvoiceTable'
import { Typography } from '@mui/material'

const Invoices = () => {
  return (
    <div>
      <Typography variant='h3' style={{ marginBottom: '5%' }}>
        Invoices
      </Typography>
      <InvoiceTable />
    </div>
  )
}

export default Invoices
