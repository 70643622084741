import React from 'react'
import styled from 'styled-components'
import { EThemeActions, useTheme, useThemeDispatch } from '../../app-context/theme-context'

import LightModeIcon from '@mui/icons-material/LightMode'
import DarkModeIcon from '@mui/icons-material/DarkMode'

const ThemeButton = () => {
  const { theme } = useTheme()
  const dispatch = useThemeDispatch()
  return (
    <ThemeButtonWrapper>
      <div className='icon-container-theme'>{theme?.name === 'light' ? <LightModeIcon style={{ color: 'orange' }} /> : <DarkModeIcon style={{ color: 'yellow' }} />}</div>
      <label className='switch'>
        <input
          type='checkbox'
          onChange={() => {
            dispatch({ type: EThemeActions.TOGGLE_THEME })
          }}
        />
        <span className='slider round'></span>
      </label>
    </ThemeButtonWrapper>
  )
}

export default ThemeButton

const ThemeButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .icon-container-theme {
    margin-bottom: 5px;
  }
  /* The switch - the box around the slider */
  .switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 20px;
  }

  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: '';
    height: 15px;
    width: 15px;
    left: 4px;
    bottom: 3px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: #2196f3;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #2196f3;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(18px);
    -ms-transform: translateX(18px);
    transform: translateX(18px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
`
