import React, { useEffect } from 'react'
import useAccountStore from './useAccountStore'
import styled from 'styled-components'
import SectionWrapper from '../components/SectionWrapper'
import FieldInput from '../../../components/global/form-inputs/FieldInput'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import IUserRes from '../../../models/interfaces/user/IUserRes'
import { Button } from '@mui/material'
import axios from 'axios'

interface IUserFormValues extends IUserRes {}

const Account = () => {
  const { userInfo } = useAccountStore()
  let initialValues: IUserFormValues = {
    _id: '',
    dateOfBirth: '',
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    address: {
      addressOne: '',
      addressTwo: '',
      city: '',
      postalCode: '',
      state: '',
    },
    companyInfo: {
      companyName: '',
      companyEmail: '',
      companyPhone: '',
      companyAddress: {
        addressOne: '',
        addressTwo: '',
        city: '',
        state: '',
        postalCode: '',
      },
    },
  }
  if (userInfo) {
    initialValues = userInfo
  }

  const validationSchema = Yup.object<IUserFormValues>({
    firstName: Yup.string().required('Required'),
    lastName: Yup.string().required('Required'),
    email: Yup.string().required('Required'),
    phone: Yup.string().required('Required'),
    dateOfBirth: Yup.string().required('Required'),
    address: Yup.object({
      addressOne: Yup.string().required('Required'),
      addressTwo: Yup.string(),
      city: Yup.string().required('Required'),
      postalCode: Yup.string().required('Required'),
      state: Yup.string().required('Required'),
    }),
    companyInfo: Yup.object({
      companyName: Yup.string().required('Required'),
      companyEmail: Yup.string().required('Required'),
      companyPhone: Yup.string().required('Required'),
      companyAddress: Yup.object({
        addressOne: Yup.string().required('Required'),
        addressTwo: Yup.string(),
        city: Yup.string().required('Required'),
        postalCode: Yup.string().required('Required'),
        state: Yup.string().required('Required'),
      }),
    }),
  })

  const submitForm = async (values: any) => {
    const res = await axios.put(`http://localhost:4000/api/user/${userInfo?._id}`, values)
    console.log(res)
  }

  return (
    <SectionWrapper title='Account'>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          console.log(values)
          submitForm(values)
        }}
      >
        <Form>
          <FormSectionWrapper showBorder={true}>
            <h3>Personal Info</h3>
            <p>Edit all your personal information below.</p>
            <FormSectionWrapper showBorder={true}>
              <OuterInputWrapper>
                <FieldInput label='First Name' name='firstName' type='text' />
                <FieldInput label='Last Name' name='lastName' type='text' />
              </OuterInputWrapper>
              <OuterInputWrapper>
                <FieldInput label='Email Address' name='email' type='email' />
                <FieldInput label='Phone' name='phone' type='text' placeholder='555-555-5555' />
              </OuterInputWrapper>
              <FieldInput label='Date of Birth' name='dateOfBirth' type='text' placeholder='10/18/1992' />
            </FormSectionWrapper>
            <FormSectionWrapper showBorder={true}>
              <OuterInputWrapper>
                <FieldInput label=' Address One' name='address.addressOne' type='text' />
                <FieldInput label='Address Two' name='address.addressTwo' type='text' />
              </OuterInputWrapper>
              <OuterInputWrapper>
                <FieldInput label='City' name='address.city' type='text' />
                <FieldInput label='State' name='address.state' type='text' />
              </OuterInputWrapper>
              <FieldInput label='PostalCode' name='address.postalCode' type='text' />
            </FormSectionWrapper>
          </FormSectionWrapper>

          <FormSectionWrapper showBorder={true}>
            <h3>Company Info</h3>
            <p>Edit all your company information below.</p>
            <FormSectionWrapper showBorder={true}>
              <OuterInputWrapper>
                <FieldInput label='Company Name' name='companyInfo.companyName' type='text' />
                <FieldInput label='Company Email' name='companyInfo.companyEmail' type='text' />
              </OuterInputWrapper>
              <FieldInput label='Company Phone' name='companyInfo.companyPhone' type='text' />
            </FormSectionWrapper>
            <FormSectionWrapper showBorder={true}>
              <OuterInputWrapper>
                <FieldInput label='Address One' name='companyInfo.companyAddress.addressOne' type='text' />
                <FieldInput label='Address Two' name='companyInfo.companyAddress.addressTwo' type='text' />
              </OuterInputWrapper>
              <OuterInputWrapper>
                <FieldInput label='City' name='companyInfo.companyAddress.city' type='text' />
                <FieldInput label='State' name='companyInfo.companyAddress.state' type='text' />
              </OuterInputWrapper>
              <FieldInput label='PostalCode' name='companyInfo.companyAddress.postalCode' type='text' />
            </FormSectionWrapper>
          </FormSectionWrapper>
          <FormSectionWrapper showBorder={false}>
            <Button variant='contained' size='large' type='submit'>
              Update
            </Button>
          </FormSectionWrapper>
        </Form>
      </Formik>
    </SectionWrapper>
  )
}

export default Account

const FormSectionWrapper = styled.div<{ showBorder: boolean }>`
  margin: 2%;
  padding: 2%;
  border: ${(props) => (props.showBorder ? '2px solid rgba(0, 0, 0, 0.05)' : 'none')};
  background-color: #fff;
  h3 {
    margin: 0;
  }

  p {
    color: rgba(0, 0, 0, 0.5);
    margin: 0;
  }
`
const OuterInputWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`
