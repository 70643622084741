import React from 'react'
import Signup from './Signup'
import { SignupContextProvider } from '../useSignupContext'

const SignupContainer = () => {
  return (
    <SignupContextProvider>
      <Signup />
    </SignupContextProvider>
  )
}

export default SignupContainer
