import React, { ReactNode } from 'react'
import styled from 'styled-components'

const StepLayoutWrapper = ({ children, title }: { children: ReactNode; title?: string }) => {
  return (
    <FormWrapper>
      <div className='heading-wrapper'>
        <h1>Trakker Pro Signup</h1>
        <h4>Business Ready!</h4>
        <div className='blob-img'></div>
      </div>

      <h2>{title}</h2>
      <div style={{ minHeight: '200px' }}>{children}</div>
    </FormWrapper>
  )
}

export default StepLayoutWrapper

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border: 2px solid rgba(0, 0, 0, 0.05);
  height: 100%;
  width: 100%;
  padding: 3% 10px;
  justify-content: center;
  box-shadow: 0 8px 18px 0 rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  z-index: 2;

  .heading-wrapper {
    background: rgb(57, 58, 61);
    background: linear-gradient(90deg, rgba(57, 58, 61, 1) 7%, rgba(202, 236, 236, 1) 175%);
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    z-index: 2;
    overflow: hidden;
    .blob-img {
      background-image: url('/assets/common/graph-green.png');
      width: 100%;
      height: 100%;
      background-size: cover;
      background-position: center;
      position: absolute;
      right: -30px;
      z-index: 0;
    }
    h1 {
      margin: 0;
      text-align: center;
      color: #fff;
      font-weight: 500;
      font-size: 50px;
      z-index: 2;
    }
    h4 {
      margin: 0;
      text-align: center;
      color: #fff;
      font-weight: 500;
      font-size: 30px;
      z-index: 2;
    }
  }
  h2 {
    margin-bottom: 30px;
    text-align: center;
    color: rgba(0, 0, 0, 0.4);
    font-weight: 600;
    border-bottom: 2px solid rgba(0, 0, 0, 0.08);
  }
`
