import React from 'react'

import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import ClientsTable from './ClientsTable'
import ClientReportCenter from './ClientReportCenter'
import { ClientContextProvider } from './hooks/clientContext'

const Clients = () => {
  return (
    <ClientContextProvider>
      <div style={{ marginTop: '3%' }}>
        <Typography variant='h3' style={{ marginBottom: '5%' }}>
          Clients
        </Typography>
        <Divider />
        <div>
          <h2>All Client Projects Report</h2>
        </div>
        <ClientReportCenter />
        <Divider />
        <ClientsTable />
      </div>
    </ClientContextProvider>
  )
}

export default Clients
