import styled from 'styled-components'

import AppModal from '../../components/global/AppModal'
import Typography from '@mui/material/Typography'
import { useClientContextActions, useClientContextState } from './hooks/clientContext'
import { useAppSelector } from '../../hooks/useRedux'

const AddClientModal = () => {
  const { userInfo } = useAppSelector((state) => state.user)
  const { displayAddClientModal, client } = useClientContextState()
  const { updateDisplayAddClientModal, updateClientInfo, saveClient } = useClientContextActions()

  return (
    <AppModal show={displayAddClientModal} closeBtnAction={() => updateDisplayAddClientModal(!displayAddClientModal)} saveBtnAction={() => saveClient(userInfo?._id || '')} size='XL'>
      <AddClientWrapper>
        <h2 className='form-title'>Add Client</h2>
        <form className='client-form'>
          <div className='col round-border'>
            <Typography variant='h5'>Business Info</Typography>
            <div className='content-lbl-wrapper'>
              <p className='label'>Business Name:</p>
              <input
                className='app-form-input cust-width'
                type='text'
                value={client.businessName}
                onChange={(e) => {
                  updateClientInfo(client, { businessName: e.target.value })
                }}
              />
            </div>
            <div className='content-lbl-wrapper'>
              <p className='label'>Email:</p>
              <input
                className='app-form-input cust-width'
                type='text'
                value={client.email}
                onChange={(e) => {
                  updateClientInfo(client, { email: e.target.value })
                }}
              />
            </div>
            <div className='content-lbl-wrapper'>
              <p className='label'>Phone:</p>
              <input
                className='app-form-input cust-width'
                type='text'
                value={client.phone}
                onChange={(e) => {
                  updateClientInfo(client, { phone: e.target.value })
                }}
              />
            </div>
            <div className='content-lbl-wrapper'>
              <p className='label'>Bill Rate ($):</p>
              <input
                className='app-form-input cust-width'
                type='number'
                min={0}
                step='0.01'
                value={client.billRate}
                onChange={(e) => {
                  updateClientInfo(client, { billRate: parseInt(e.target.value) })
                }}
              />
            </div>
          </div>
          <div className='col round-border'>
            <Typography variant='h5'>Address</Typography>
            <div className='content-lbl-wrapper'>
              <p className='label'>Address One:</p>
              <input
                className='app-form-input cust-width cust-width'
                type='text'
                value={client.address?.addressOne}
                onChange={(e) => {
                  updateClientInfo(client, { address: { ...client.address, addressOne: e.target.value } })
                }}
              />
            </div>
            <div className='content-lbl-wrapper'>
              <p className='label'>Address Two:</p>
              <input
                className='app-form-input cust-width'
                type='text'
                value={client.address?.addressTwo}
                onChange={(e) => {
                  updateClientInfo(client, { address: { ...client.address, addressTwo: e.target.value } })
                }}
              />
            </div>
            <div className='content-lbl-wrapper'>
              <p className='label'>City:</p>
              <input
                className='app-form-input cust-width'
                type='text'
                value={client.address?.city}
                onChange={(e) => {
                  updateClientInfo(client, { address: { ...client.address, city: e.target.value } })
                }}
              />
            </div>
            <div className='content-lbl-wrapper'>
              <p className='label'>State:</p>
              <input
                className='app-form-input cust-width'
                type='text'
                value={client.address?.state}
                onChange={(e) => {
                  updateClientInfo(client, { address: { ...client.address, state: e.target.value } })
                }}
              />
            </div>
            <div className='content-lbl-wrapper'>
              <p className='label'>Postal Code:</p>
              <input
                className='app-form-input cust-width'
                type='text'
                value={client.address?.postalCode}
                onChange={(e) => {
                  updateClientInfo(client, { address: { ...client.address, postalCode: e.target.value } })
                }}
              />
            </div>
          </div>
          <div className='col round-border'>
            <Typography variant='h5'>Contact</Typography>
            <div className='content-lbl-wrapper'>
              <p className='label'>First Name:</p>
              <input
                className='app-form-input cust-width'
                type='text'
                value={client.contact?.firstName}
                onChange={(e) => {
                  updateClientInfo(client, { contact: { ...client.contact, firstName: e.target.value } })
                }}
              />
            </div>
            <div className='content-lbl-wrapper'>
              <p className='label'>Last Name:</p>
              <input
                className='app-form-input cust-width'
                type='text'
                value={client.contact.lastName}
                onChange={(e) => {
                  updateClientInfo(client, { contact: { ...client.contact, lastName: e.target.value } })
                }}
              />
            </div>
            <div className='content-lbl-wrapper'>
              <p className='label'>Email:</p>
              <input
                className='app-form-input cust-width'
                type='text'
                value={client.contact.email}
                onChange={(e) => {
                  updateClientInfo(client, { contact: { ...client.contact, email: e.target.value } })
                }}
              />
            </div>
            <div className='content-lbl-wrapper'>
              <p className='label'>Phone:</p>
              <input
                className='app-form-input cust-width'
                type='text'
                value={client.contact.phone}
                onChange={(e) => {
                  updateClientInfo(client, { contact: { ...client.contact, phone: e.target.value } })
                }}
              />
            </div>
          </div>
        </form>
      </AddClientWrapper>
    </AppModal>
  )
}

export default AddClientModal

const AddClientWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  .form-title {
    font-size: 45px;
    background-color: var(--app-theme-color-primary);
    width: 100%;
    height: 100px;
    margin-top: 0;
    border-radius: 18px 18px 0 0;
    text-align: center;
  }

  .client-form {
    display: flex;
    flex-direction: row;
    padding: 2%;
    min-height: 250px;

    .col {
      border: 2px solid rgba(0, 0, 0, 0.2);
      width: 50%;
      padding: 0 2%;
      margin: 0 5px;
      border-radius: 5px;
    }

    .label {
      margin: 0;
    }

    .cust-width {
      width: 200px;
    }
  }

  .error-txt {
    color: #d32f2f;
    font-size: 14px;
    text-align: center;
  }
`
