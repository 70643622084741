import { createContext, useContext, useReducer, ReactNode } from 'react'
import { trackerService } from '../../../services'
import ICreateClientReq from '../../../models/interfaces/clients/ICreateClientReq'

interface IAddress {
  addressOne: string
  addressTwo: string
  city: string
  state: string
  postalCode: string
}

interface IContact {
  firstName: string
  lastName: string
  email: string
  phone: string
}

interface IClient {
  _id?: string
  businessName: string
  email: string
  phone: string
  billRate: number
  address: IAddress
  contact: IContact
}

interface IClientState {
  clients: Array<IClient> | undefined
  client: IClient
  displayAddClientModal: boolean
}
const initalClient: IClient = {
  businessName: '',
  email: '',
  phone: '',
  billRate: 0,
  address: {
    addressOne: '',
    addressTwo: '',
    city: '',
    state: '',
    postalCode: '',
  },
  contact: {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
  },
}

const initalState: IClientState = {
  clients: undefined,
  client: initalClient,
  displayAddClientModal: false,
}

const ClientContext = createContext<IClientState | undefined>(undefined)

enum EClientActions {
  Fetch_Clients = 'Fetch Clients',
  Update_Client_Info = 'Update_Client_Info',
  Display_Add_Client_Modal = 'Display_Add_Client_Modal',
}

type IFetchClients = { type: EClientActions.Fetch_Clients; payload: IClient[] | any }
type IUpdateClientInfo = { type: EClientActions.Update_Client_Info; payload: IClient }
type IDisplayAddClientModal = { type: EClientActions.Display_Add_Client_Modal; payload: boolean }
type IAction = IFetchClients | IUpdateClientInfo | IDisplayAddClientModal
type IDispatch = (action: IAction) => void
const ClientDispatchContext = createContext<IDispatch | undefined>(undefined)

function clientReducer(state: IClientState, action: IAction) {
  switch (action.type) {
    case EClientActions.Fetch_Clients: {
      return {
        ...state,
        clients: action.payload,
      }
    }
    case EClientActions.Display_Add_Client_Modal: {
      return {
        ...state,
        displayAddClientModal: action.payload,
      }
    }
    case EClientActions.Update_Client_Info: {
      return {
        ...state,
        client: action.payload,
      }
    }
    default:
      return state
  }
}

export const ClientContextProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [state, dispatch] = useReducer(clientReducer, initalState)
  return (
    <ClientContext.Provider value={state}>
      <ClientDispatchContext.Provider value={dispatch}>{children}</ClientDispatchContext.Provider>
    </ClientContext.Provider>
  )
}

export function useClientContextState() {
  const context = useContext(ClientContext)
  if (context === undefined) {
    throw new Error('Context For Client is Undefined')
  }
  return context
}

export function useClientContextActions(): {
  fetchClients: (arg: string) => void
  updateDisplayAddClientModal: (arg: boolean) => void
  updateClientInfo: (arg: IClient, arg2: Partial<IClient>) => void
  saveClient: (userId: string) => void
} {
  const dispatch = useContext(ClientDispatchContext)
  const context = useContext(ClientContext)
  if (dispatch === undefined) {
    throw new Error('Context For Client is Undefined')
  }

  const fetchClientsAsync = async (id: string) => {
    const res = await trackerService.getClients(id)
    dispatch({ type: EClientActions.Fetch_Clients, payload: res.data })
  }

  const saveClientAsync = async (userId: string) => {
    if (!context?.client.businessName || !context?.client.email || !context?.client.phone || !context?.client.billRate || !context?.client.address.addressOne || !context?.client.address.city || !context?.client.address.state || !context?.client.address.postalCode || !context?.client.contact.firstName || !context?.client.contact.lastName || !context?.client.contact.email) {
      return
    }

    const createClientReq: ICreateClientReq = {
      ...context.client,
      notes: '',
      userId: userId,
    }
    const updateRes = await trackerService.saveClient(createClientReq)

    if (updateRes.success) {
      // toast.success('New Client Saved.')
      dispatch({ type: EClientActions.Update_Client_Info, payload: initalClient })
      dispatch({ type: EClientActions.Display_Add_Client_Modal, payload: false })
      await fetchClientsAsync(userId)
    } else if (!updateRes.success) {
      // toast.error('Could Not Save Client.')
    }
  }

  // Return all Actions from Hook
  return {
    fetchClients: (arg) => fetchClientsAsync(arg),
    updateDisplayAddClientModal: (payload) => dispatch({ type: EClientActions.Display_Add_Client_Modal, payload }),
    updateClientInfo: (arg, arg2) => dispatch({ type: EClientActions.Update_Client_Info, payload: { ...arg, ...arg2 } }),
    saveClient: (arg) => saveClientAsync(arg),
  }
}
