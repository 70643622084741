import React, { InputHTMLAttributes } from 'react'
import { useField } from 'formik'
import styled from 'styled-components'

interface IFieldInputProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string
}

const FieldInput: React.FC<IFieldInputProps> = ({ label, ...props }) => {
  const [field, meta] = useField(props as any)
  return (
    <InputWrapper>
      <label htmlFor={props.id || props.name}>{label}</label>
      <input {...field} {...props} />
      {meta.touched && meta.error ? <div className='error'>{meta.error}</div> : null}
    </InputWrapper>
  )
}

export default FieldInput

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;

  label {
    color: rgba(0, 0, 0, 0.6);
  }

  input {
    width: 300px;
    height: 40px;
    border: 2px solid rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding-left: 8px;
    background-color: inherit; //#eeeeee
    font-size: 16px;
  }

  .error {
    color: crimson;
  }
`
