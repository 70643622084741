import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import Button from '@mui/material/Button'

interface AppModalProps {
  show: boolean
  size?: 'MED' | 'LG' | 'XL'
  title?: string
  children?: any
  closeBtnAction: any
  saveBtnAction: any
  buttonOneTitle?: string
  buttonTwoTitle?: string
}

const AppModal: React.FC<AppModalProps> = ({ show, title, children, closeBtnAction, saveBtnAction, size = 'MED', buttonOneTitle = 'Save', buttonTwoTitle = 'Close' }) => {
  return (
    <AppModalWraper show={show} size={size}>
      <div className='outer-content-wrapper'>
        <div className='modal-content'>{children}</div>
        <div className='button-wrapper'>
          <Button
            variant='contained'
            color='error'
            onClick={() => {
              closeBtnAction(false)
            }}
          >
            {buttonTwoTitle}
          </Button>
          <Button
            variant='contained'
            style={{ backgroundColor: 'var(--app-green)' }}
            onClick={() => {
              saveBtnAction()
            }}
          >
            {buttonOneTitle}
          </Button>
        </div>
      </div>
    </AppModalWraper>
  )
}

export default AppModal

const AppModalWraper = styled.div<{ show: boolean; size: 'MED' | 'LG' | 'XL' }>`
  display: ${(props) => (props.show ? 'flex' : 'none')};
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 999;

  .outer-content-wrapper {
    width: ${(props) => (props.size === 'MED' ? '50%' : props.size === 'LG' ? '75%' : '95%')};
    background-color: #fff;
    min-height: 200px;
    border-radius: 18px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .modal-content {
      display: flex;
      justify-content: center;
      min-height: 225px;
      width: 100%;
    }

    .button-wrapper {
      width: ${(props) => (props.size === 'MED' ? '40%' : props.size === 'LG' ? '30%' : '20%')};
      display: flex;
      justify-content: space-between;
      padding: 2% 0;
    }
  }
`
