import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../../store'
import IUserRes from '../../../models/interfaces/user/IUserRes'

// Define a type for the slice state
interface UserState {
  userInfo: IUserRes | null
  status: {
    loggedIn: boolean
  }
}

// Define the initial state using that type
const initialState: UserState = {
  userInfo: null,
  status: {
    loggedIn: false,
  },
}

export const userSlice = createSlice({
  name: 'user',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    updateUserInfo: (state, action: PayloadAction<IUserRes>) => {
      state.userInfo = action.payload
    },
    updateUserStatus: (state, action: PayloadAction<boolean>) => {
      state.status.loggedIn = action.payload
    },
    resetUserState: () => {
      return initialState
    },
  },
})

export const { updateUserInfo, updateUserStatus, resetUserState } = userSlice.actions

// Other code such as selectors can use the imported `RootState` type
// export const selectCount = (state: RootState) => state.counter.value

export default userSlice.reducer
