import React, { useContext, useReducer } from 'react'

const themes = {
  light: {
    name: 'light',
    foreground: '#000000',
    background: '#eeeeee',
  },
  dark: {
    name: 'dark',
    foreground: '#ffffff',
    background: '#222222',
  },
}

export enum EThemeActions {
  TOGGLE_THEME = 'TOGGLE_THEME',
}

type TToggleTheme = { type: EThemeActions.TOGGLE_THEME }

type TAction = TToggleTheme

type TDispatch = (action: TAction) => void

const AppThemeContext = React.createContext<ThemeState | undefined>(undefined)
const AppThemeDispatchContext = React.createContext<TDispatch | undefined>(undefined)

export function ThemeProvider({ children }: any) {
  const [state, dispatch] = useReducer(themeReducer, initalTheme)

  return (
    <AppThemeContext.Provider value={state}>
      <AppThemeDispatchContext.Provider value={dispatch}>{children}</AppThemeDispatchContext.Provider>
    </AppThemeContext.Provider>
  )
}

export function useTheme() {
  const context = useContext(AppThemeContext)
  if (context === undefined) {
    throw new Error('Theme contecxt is undefined')
  }
  return context
}

export function useThemeDispatch() {
  const context = useContext(AppThemeDispatchContext)
  if (context === undefined) {
    throw new Error('Theme contecxt is undefined')
  }
  return context
}

function themeReducer(state: ThemeState, action: TAction) {
  switch (action.type) {
    case EThemeActions.TOGGLE_THEME: {
      return {
        theme: state.theme.name === 'light' ? themes.dark : themes.light,
      }
    }
    default:
      return state
  }
}

interface ThemeState {
  theme: {
    name: string
    foreground: string
    background: string
  }
}

const initalTheme: ThemeState = {
  theme: themes.light,
}
