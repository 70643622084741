import { EStatus } from '../models/Enums/EStatus'
import { clearStorageValues } from '../services/storage/app-storage-api'
import { resetClientState } from '../store/features/clients/clientsSlice'
import { resetProjectState } from '../store/features/project/projectSlice'
import { resetUserState } from '../store/features/user/userSlice'

export const logout = (dispatch: any, navigate: any) => {
  // clear all state
  dispatch(resetUserState())
  dispatch(resetClientState())
  dispatch(resetProjectState())
  // remove localstorage keys
  clearStorageValues()
  // navigate to login page
  navigate('/login')
}

/**
 *  This determines the chip colors depending on the EStatus Arg being passed in and returns the color in HEX
 * @param status : EStatus
 * @returns string
 */
export const determineChipColor = (status: string) => {
  switch (status) {
    case EStatus.New:
      return 'rgba(253, 155, 61, .5)'
    case EStatus.Wip:
      return 'rgba(134, 138, 212, .5)'
    case EStatus.Hold:
      return 'rgba(38, 193, 192, .5)'
    case EStatus.Complete:
      return 'rgba(136, 190, 125, .5)'
    default:
      return ' #7f7f7f'
  }
}

/**
 *
 * @param date string
 * @returns Date only string of a System DateTime
 */
export const handleTruncateTimeFromSysDate = (date: string | null) => {
  return date ? date.split('T')[0] : ''
}

/**
 *
 * @param date string
 * @returns a USA locale formatted date
 */
export const displaySysTimeToReadable = (date: string | null) => {
  if (date) {
    const formatedDate = new Date(date)
    return `${formatedDate.getMonth() + 1}/${formatedDate.getDate()}/${formatedDate.getFullYear()}`
  } else {
    return ''
  }
}
