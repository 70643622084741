import React from 'react'
import styled from 'styled-components'

interface IBasicCardProps {
  title: string
  value: string | number
  color?: string
  children?: any
}

const BasicCard: React.FC<IBasicCardProps> = ({ title, value, color, children }) => {
  return (
    <BasicCardWrapper color={color || undefined}>
      <p className='title-txt'>{title}</p>
      <p className='value-txt'>{value.toString()}</p>
      <div>{children}</div>
    </BasicCardWrapper>
  )
}

export default BasicCard

const BasicCardWrapper = styled.div<{ color?: string }>`
  min-width: 150px;
  min-height: 150px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-shadow: 0 8px 18px 0 rgba(0, 0, 0, 0.2);
  border-radius: 12px;
  background-color: ${(props) => (props.color ? props.color : '#fff')};
  transition: all 0.5s ease-in-out;

  :hover {
    transform: scale(1.02);
  }

  .title-txt {
    font-weight: 600;
    font-size: 18px;
    margin: 0 0 10px 0;
  }

  .value-txt {
    font-weight: 700;
    font-size: 40px;
    margin: 0;
  }
`
