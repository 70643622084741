import React, { useEffect } from 'react'
import { useAppDispatch } from './hooks/useRedux'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import CssBaseline from '@mui/material/CssBaseline'
import Divider from '@mui/material/Divider'
import Drawer from '@mui/material/Drawer'
import IconButton from '@mui/material/IconButton'
import InboxIcon from '@mui/icons-material/MoveToInbox'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import MailIcon from '@mui/icons-material/Mail'
import MenuIcon from '@mui/icons-material/Menu'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import BusinessIcon from '@mui/icons-material/Business'
import ReceiptIcon from '@mui/icons-material/Receipt'
import PaidIcon from '@mui/icons-material/Paid'
import LoginIcon from '@mui/icons-material/Login'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import SettingsIcon from '@mui/icons-material/Settings'
import { Outlet, Link, useNavigate } from 'react-router-dom'
import UserInfo from './components/UserInfo'
import { useAppSelector } from './hooks/useRedux'
import { logout } from './common/globalHelperFunctions'
import { Toaster } from 'react-hot-toast'
import { useUserStatus } from './hooks/useUserStatus'
import ThemeButton from './components/global/ThemeButton'
import { useTheme } from './app-context/theme-context'

const drawerWidth = 240

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window
}

export default function App(props: Props) {
  const { theme } = useTheme()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { isLoggedIn } = useUserStatus()
  const { userInfo } = useAppSelector((state) => state.user)
  const { status } = useAppSelector((state) => state.user)
  const [mobileOpen, setMobileOpen] = React.useState(false)
  const { window } = props

  let loginCount = 0

  useEffect(() => {
    if (!isLoggedIn && loginCount === 0) {
      navigate('login')
      loginCount++
    }
  }, [])

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  const drawer = (
    <div style={{ position: 'relative', height: '100%' }}>
      <Toolbar style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', backgroundColor: 'var(--app-theme-color-tertiary)' }}>
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', backgroundColor: 'var(--app-theme-color-tertiary)' }}>
          <div style={{ border: '1px solid #000', borderRadius: '50%', padding: '3%', height: '35px' }}>
            <p style={{ padding: 0, margin: 0 }}>TP</p>
          </div>
          <Typography variant='h6' noWrap component='div' style={{ color: 'var(--primary-text-color)', width: '70%', textAlign: 'center' }}>
            Trakker Pro
          </Typography>
        </div>
        {userInfo?.companyInfo.companyName && (
          <Typography variant='body1' noWrap component='div' style={{ color: 'var(--primary-text-color)', width: '70%', textAlign: 'center' }}>
            ({userInfo?.companyInfo.companyName})
          </Typography>
        )}
      </Toolbar>

      <Divider style={{ backgroundColor: '#fff' }} />
      {isLoggedIn && (
        <List style={{ backgroundColor: '#393a3d' }}>
          {[
            { name: 'Clients', icon: BusinessIcon, link: 'clients' },
            { name: 'Invoices', icon: ReceiptIcon, link: 'invoices' },
            { name: 'Income', icon: PaidIcon, link: 'income' },
          ].map((link, index) => (
            <Link to={link.link} key={index} style={{ textDecoration: 'none', color: 'rgba(0,0,0,0.4)' }}>
              <ListItem disablePadding>
                <ListItemButton>
                  <ListItemIcon>{<link.icon style={{ color: '#fff' }} />}</ListItemIcon>
                  <ListItemText primary={link.name} style={{ color: '#fff' }} />
                </ListItemButton>
              </ListItem>
            </Link>
          ))}
        </List>
      )}
      <Divider style={{ backgroundColor: '#fff' }} />
      <List style={{ backgroundColor: '#393a3d', position: 'absolute', bottom: '0px' }}>
        {!isLoggedIn && (
          <Link to='login' style={{ textDecoration: 'none', color: 'rgba(0,0,0,0.4)' }}>
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon>{<LoginIcon style={{ color: '#fff' }} />}</ListItemIcon>
                <ListItemText primary={'Login'} style={{ color: '#fff' }} />
              </ListItemButton>
            </ListItem>
          </Link>
        )}
        {status.loggedIn && (
          <>
            <ListItem disablePadding>
              <ListItemButton onClick={() => logout(dispatch, navigate)}>
                <ListItemIcon>{<ExitToAppIcon style={{ color: '#fff' }} />}</ListItemIcon>
                <ListItemText primary={'logout'} style={{ color: '#fff' }} />
              </ListItemButton>
            </ListItem>

            <Link to='settings/account'>
              <ListItem disablePadding>
                <ListItemButton>
                  <ListItemIcon>{<SettingsIcon style={{ color: '#fff' }} />}</ListItemIcon>
                  <ListItemText primary={'Settings'} style={{ color: '#fff' }} />
                </ListItemButton>
              </ListItem>
            </Link>
          </>
        )}
      </List>
    </div>
  )

  const container = window !== undefined ? () => window().document.body : undefined

  return (
    <Box sx={{ display: 'flex', minHeight: '100vh' }}>
      <Toaster
        toastOptions={{
          duration: 5000,
        }}
      />
      <CssBaseline />
      <AppBar
        position='fixed'
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
          boxShadow: 'none',
          backgroundColor: `${theme.background}`,
          color: `${theme.foreground}`,
        }}
      >
        <Toolbar style={{ width: '100%' }}>
          <IconButton color='inherit' aria-label='open drawer' edge='start' onClick={handleDrawerToggle} sx={{ mr: 2, display: { sm: 'none' }, backgroundColor: '#505050' }}>
            <MenuIcon />
          </IconButton>
          <Typography variant='h6' noWrap component='div' style={{ color: 'var(--primary-text-color)', width: '90%' }}></Typography> {/*Empty space for the header */}
          <div style={{ display: 'flex', paddingTop: '10px' }}>
            <UserInfo name={userInfo?.firstName || 'Guest'} profileImg={userInfo?.profileImg} />
          </div>
          <div style={{ display: 'flex', paddingTop: '10px', marginLeft: '20px' }}>
            <ThemeButton />
          </div>
        </Toolbar>
      </AppBar>
      <Box component='nav' sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }} aria-label='mailbox folders'>
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant='temporary'
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, backgroundColor: '#393a3d' },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant='permanent'
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, backgroundColor: '#393a3d' },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box component='main' sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` }, backgroundColor: `${theme.background}`, color: `${theme.foreground}`, position: 'relative' }}>
        <Toolbar />
        <Outlet />
      </Box>
    </Box>
  )
}
