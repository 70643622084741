import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../../store'
import IProject from '../../../models/interfaces/project/IProject'

// Define a type for the slice state
interface UserState {
  project: IProject | null
}

// Define the initial state using that type
const initialState: UserState = {
  project: null,
}

export const projectSlice = createSlice({
  name: 'project',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    updateProjectInfo: (state, action: PayloadAction<IProject>) => {
      state.project = action.payload
    },
    resetProjectState: (state) => {
      return initialState
    },
  },
})

export const { updateProjectInfo, resetProjectState } = projectSlice.actions

// Other code such as selectors can use the imported `RootState` type
// export const selectCount = (state: RootState) => state.counter.value

export default projectSlice.reducer
