import { IApiClient } from './ApiClient'
import ISignInReq from '../models/interfaces/signin/ISignInReq'
import ISignInRes from '../models/interfaces/signin/ISignInRes'
import IServiceResponse from './interfaces/IServiceRespone'
import IUserRes from '../models/interfaces/user/IUserRes'
import IClient from '../models/interfaces/clients/IClient'
import IProject from '../models/interfaces/project/IProject'
import ISaveProjectReq from '../models/interfaces/project/ISaveProjectReq'
import ISaveTaskReq from '../models/interfaces/project/ISaveTaskReq'
import IProjectStatusReportRes from '../models/interfaces/project/IProjectStatusReportsRes'
import IUpdateProjectReq from '../models/interfaces/project/IUpdateProjectReq'
import IUpdateClientReq from '../models/interfaces/clients/IUpdateClientReq'
import IProjectStatusIncomeReq from '../models/interfaces/clients/IProjectStatusIncomeReq'
import ICreateClientReq from '../models/interfaces/clients/ICreateClientReq'
import IInvoicePDF from '../models/interfaces/pdfs/IInvoicePDF'
import IInvoiceReportReq from '../models/interfaces/invoices/IInoviceReportReq'
import ISignupReq from '../models/interfaces/signup/ISignupReq'
import { Client } from '../domains/client/models/Client'
import ISendInvoiceReq from '../models/interfaces/invoices/ISendInvoiceReq'

export interface ITrackerApiClient {
  signup(data: ISignupReq): Promise<any | undefined>
  updateToken(accessToken: string): void
  updateUserId(userId: string): void
  signin(reqData: ISignInReq): Promise<any | undefined>
  getUserInfo(userId: string): Promise<any | undefined>
  getClients(userId: string): Promise<any | undefined>
  getClientDetail(clientId: string): Promise<any | undefined>
  getClientProjects(clientId: string): Promise<any | undefined>
  updateClient(clientId: string, data: IUpdateClientReq): Promise<any | undefined>
  saveClient(data: ICreateClientReq): Promise<any | undefined>
  saveProject(data: ISaveProjectReq): Promise<any | undefined>
  updateProject(projectId: string, data: IUpdateProjectReq): Promise<any | undefined>
  getProject(projectId: string): Promise<any | undefined>
  saveTask(projectId: string, data: ISaveTaskReq): Promise<any | undefined>
  getProjectStatusCountsReport(userId: string): Promise<any | undefined>
  getClientsProjectStatusReportForIncome(clientId: string, data: IProjectStatusIncomeReq): Promise<any | undefined>
  getCurrentInvoiceNumber(userId: string): Promise<any | undefined>
  createInvoice(data: IInvoicePDF): Promise<any | undefined>
  getInvoices(userId: string): Promise<any | undefined>
  getInvoice(invoiceId: string): Promise<any | undefined>
  updateInvoicePaidStatus(data: { invoiceId: string; invoiceIdList: Array<string>; updateProjectPayload: { isPaid: boolean } }): Promise<any | undefined>
  filterProjects(data: any): Promise<any | undefined>
  reportInvoices(data: IInvoiceReportReq): Promise<any | undefined>
  sendInvoice(data: ISendInvoiceReq): Promise<any | undefined>
}

export class TrackerApiClient implements ITrackerApiClient {
  public trackerApiClient: IApiClient

  constructor(trackerApiClient: IApiClient) {
    this.trackerApiClient = trackerApiClient
  }

  async signup(data: ISignupReq): Promise<any | undefined> {
    try {
      const response = await this.trackerApiClient.post<ISignupReq, any>(`/api/user`, data)
      return response
    } catch (exception) {
      console.error(exception)
    }
  }

  updateToken(accessToken: string) {
    this.trackerApiClient.updateClientToken(accessToken)
  }

  updateUserId(userId: string): void {
    this.trackerApiClient.updateUserIdInHeader(userId)
  }

  async signin(reqData: ISignInReq): Promise<any> {
    try {
      const response = await this.trackerApiClient.post<ISignInReq, ISignInRes>(`/api/login`, reqData)
      return response
    } catch (exception) {
      console.error(exception)
    }
  }

  async getUserInfo(userId: string): Promise<any> {
    try {
      const response = await this.trackerApiClient.get<any>(`/api/user/${userId}`)
      return response
    } catch (exception) {
      console.error(exception)
    }
  }

  async saveClient(data: ICreateClientReq): Promise<any> {
    try {
      const response = await this.trackerApiClient.post<ICreateClientReq, any>(`/api/client`, data)
      return response
    } catch (exception) {
      console.error(exception)
    }
  }

  async getClients(userId: string): Promise<any> {
    try {
      const response = await this.trackerApiClient.get<any>(`/api/client/clients/${userId}`)
      response.data = response.data.map((data: any) => {
        return Client.fromProperites(data)
      })
      return response
    } catch (exception) {
      console.error(exception)
    }
  }

  async getClientDetail(clientId: string): Promise<any> {
    try {
      const response = await this.trackerApiClient.get<any>(`/api/client/${clientId}`)
      return response
    } catch (exception) {
      console.error(exception)
    }
  }

  async getClientProjects(clientId: string): Promise<any> {
    try {
      const response = await this.trackerApiClient.get<any>(`/api/project/projects/${clientId}`)
      return response
    } catch (exception) {
      console.error(exception)
    }
  }

  async saveProject(data: ISaveProjectReq): Promise<any> {
    try {
      const response = await this.trackerApiClient.post<any, any>(`/api/project`, data)
      return response
    } catch (exception) {
      console.error(exception)
    }
  }

  async getProject(projectId: string): Promise<any> {
    try {
      const response = await this.trackerApiClient.get<any>(`/api/project/${projectId}`)
      return response
    } catch (exception) {
      console.error(exception)
    }
  }

  async saveTask(projectId: string, data: ISaveTaskReq): Promise<any> {
    try {
      const response = await this.trackerApiClient.put<ISaveTaskReq, any>(`/api/project/${projectId}`, data)
      return response
    } catch (exception) {
      console.error(exception)
    }
  }

  async getProjectStatusCountsReport(userId: string): Promise<any> {
    try {
      const response = await this.trackerApiClient.get<any>(`/api/project/report/counts/${userId}`)
      return response
    } catch (exception) {
      console.error(exception)
    }
  }

  async updateProject(projectId: string, data: IUpdateProjectReq): Promise<any> {
    try {
      const response = await this.trackerApiClient.put<IUpdateProjectReq, any>(`/api/project/${projectId}`, data)
      return response
    } catch (exception) {
      console.error(exception)
    }
  }

  async updateClient(clientId: string, data: IUpdateClientReq): Promise<any | undefined> {
    try {
      const response = await this.trackerApiClient.put<IUpdateClientReq, any>(`/api/client/${clientId}`, data)
      return response
    } catch (exception) {
      console.error(exception)
    }
  }

  async getClientsProjectStatusReportForIncome(clientId: string, data: IProjectStatusIncomeReq): Promise<any | undefined> {
    try {
      const response = await this.trackerApiClient.post<IProjectStatusIncomeReq, any>(`/api/project/report/bystatus/${clientId}`, data)
      return response
    } catch (exception) {
      console.error(exception)
    }
  }

  async getCurrentInvoiceNumber(userId: string): Promise<any | undefined> {
    try {
      const response = await this.trackerApiClient.get<any>(`/api/invoice/number/current/${userId}`)
      return response
    } catch (exception) {
      console.error(exception)
    }
  }

  async createInvoice(data: IInvoicePDF): Promise<any | undefined> {
    try {
      const response = await this.trackerApiClient.post<IInvoicePDF, any>('/api/invoice', data)
      return response
    } catch (exception) {
      console.error(exception)
    }
  }

  async getInvoices(userId: string): Promise<any | undefined> {
    try {
      const response = await this.trackerApiClient.get<any>(`/api/invoice/invoices/${userId}`)
      return response
    } catch (exception) {
      console.error(exception)
    }
  }
  async getInvoice(invoiceId: string): Promise<any | undefined> {
    try {
      const response = await this.trackerApiClient.get<any>(`/api/invoice/${invoiceId}`)
      return response
    } catch (exception) {
      console.error(exception)
    }
  }
  async updateInvoicePaidStatus(data: { invoiceId: string; invoiceIdList: Array<string>; updateProjectPayload: { isPaid: boolean } }): Promise<any | undefined> {
    try {
      const response = await this.trackerApiClient.post<{ invoiceId: string; invoiceIdList: Array<string>; updateProjectPayload: { isPaid: boolean } }, any>(`/api/invoice/update-paid-status`, data)
      return response
    } catch (exception) {
      console.error(exception)
    }
  }

  async filterProjects(data: any): Promise<any | undefined> {
    try {
      const response = await this.trackerApiClient.post<any, any>(`/api/project/filter`, data)
      return response
    } catch (exception) {
      console.error(exception)
    }
  }

  async reportInvoices(data: IInvoiceReportReq): Promise<any | undefined> {
    try {
      const response = await this.trackerApiClient.post<IInvoiceReportReq, any>(`/api/invoice/report`, data)
      return response
    } catch (exception) {
      console.error(exception)
    }
  }

  async sendInvoice(data: ISendInvoiceReq): Promise<any | undefined> {
    try {
      const response = await this.trackerApiClient.post<ISendInvoiceReq, any>(`/api/invoice/email`, data)
      return response
    } catch (exception) {
      console.error(exception)
    }
  }
}

export default class TrackerService {
  private trackerApiClient: ITrackerApiClient

  constructor(trackerApiClient: ITrackerApiClient) {
    this.trackerApiClient = trackerApiClient
  }

  async singup(data: ISignupReq): Promise<IServiceResponse<any>> {
    return this.trackerApiClient.signup(data)
  }

  updateToken(accessToken: string) {
    this.trackerApiClient.updateToken(accessToken)
  }

  updateUserId(userId: string): void {
    this.trackerApiClient.updateUserId(userId)
  }

  async signin(reqData: ISignInReq): Promise<IServiceResponse<ISignInRes>> {
    return this.trackerApiClient.signin(reqData)
  }

  async saveClient(data: ICreateClientReq): Promise<IServiceResponse<any>> {
    return this.trackerApiClient.saveClient(data)
  }

  async getUserInfo(userId: string): Promise<IServiceResponse<IUserRes>> {
    return this.trackerApiClient.getUserInfo(userId)
  }

  async getClients(userId: string): Promise<IServiceResponse<Array<IClient>>> {
    return this.trackerApiClient.getClients(userId)
  }

  async getClient(clientId: string): Promise<IServiceResponse<IClient>> {
    return this.trackerApiClient.getClientDetail(clientId)
  }

  async getClientProjects(clientId: string): Promise<IServiceResponse<Array<IProject>>> {
    return this.trackerApiClient.getClientProjects(clientId)
  }

  async saveProject(data: ISaveProjectReq): Promise<IServiceResponse<any>> {
    return this.trackerApiClient.saveProject(data)
  }

  async getProject(projectId: string): Promise<IServiceResponse<IProject>> {
    return this.trackerApiClient.getProject(projectId)
  }

  async saveTask(projectId: string, data: ISaveTaskReq): Promise<IServiceResponse<any>> {
    return this.trackerApiClient.saveTask(projectId, data)
  }

  async getProjectStatusCountsReport(userId: string): Promise<IServiceResponse<IProjectStatusReportRes>> {
    return this.trackerApiClient.getProjectStatusCountsReport(userId)
  }

  async updateProject(projectId: string, data: IUpdateProjectReq): Promise<IServiceResponse<any>> {
    return this.trackerApiClient.updateProject(projectId, data)
  }

  async updateClient(clientId: string, data: IUpdateClientReq): Promise<IServiceResponse<any>> {
    return this.trackerApiClient.updateClient(clientId, data)
  }

  async getClientsProjectStatusReportForIncome(clientId: string, data: IProjectStatusIncomeReq): Promise<IServiceResponse<any>> {
    return this.trackerApiClient.getClientsProjectStatusReportForIncome(clientId, data)
  }

  async getCurrentInvoiceNumber(userId: string): Promise<IServiceResponse<{ _id: string; invoiceNumber: number; userId: string }>> {
    return this.trackerApiClient.getCurrentInvoiceNumber(userId)
  }

  async createInvoice(data: IInvoicePDF): Promise<IServiceResponse<any>> {
    return this.trackerApiClient.createInvoice(data)
  }

  async getInvoices(userId: string): Promise<IServiceResponse<Array<IInvoicePDF>>> {
    return this.trackerApiClient.getInvoices(userId)
  }

  async getInvoice(invoiceId: string): Promise<IServiceResponse<IInvoicePDF>> {
    return this.trackerApiClient.getInvoice(invoiceId)
  }

  async updateInvoicePaidStatus(data: { invoiceId: string; invoiceIdList: Array<string>; updateProjectPayload: { isPaid: boolean } }): Promise<IServiceResponse<any>> {
    return this.trackerApiClient.updateInvoicePaidStatus(data)
  }

  async filterProjects(data: any): Promise<IServiceResponse<any>> {
    return this.trackerApiClient.filterProjects(data)
  }

  async reportInvoices(data: IInvoiceReportReq): Promise<IServiceResponse<any>> {
    return this.trackerApiClient.reportInvoices(data)
  }

  async sendInvoice(data: ISendInvoiceReq): Promise<IServiceResponse<any>> {
    return this.trackerApiClient.sendInvoice(data)
  }
}
