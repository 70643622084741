// https://bionicjulia.com/blog/axios-wrappers-react-typescript
// import appConfig from 'appConfig'
import Axios, { AxiosInstance } from 'axios'
import { RequestConfig } from './config/types'
import { ApiConfiguration } from './config/ApiConfiguration'
// import { handleServiceError } from './ApiServiceErrors'

export interface IApiClient {
  post<TRequest, TResponse>(path: string, object: TRequest, config?: RequestConfig): Promise<TResponse>
  patch<TRequest, TResponse>(path: string, object: TRequest): Promise<TResponse>
  put<TRequest, TResponse>(path: string, object: TRequest): Promise<TResponse>
  get<TResponse>(path: string): Promise<TResponse>
  updateClientToken(accessToken: string): void
  updateUserIdInHeader(userId: string): void
}

export default class ApiClient implements IApiClient {
  private client: AxiosInstance

  protected createAxiosClient(apiConfiguration: ApiConfiguration): AxiosInstance {
    return Axios.create({
      baseURL: apiConfiguration.baseURL,
      responseType: 'json' as const,
      headers: {
        'Content-Type': 'application/json',
        ...(apiConfiguration.accessToken && {
          Authorization: `Bearer ${apiConfiguration.accessToken}`,
        }),
      },
      timeout: 10 * 1000,
    })
  }

  constructor(apiConfiguration: ApiConfiguration) {
    this.client = this.createAxiosClient(apiConfiguration)
  }

  updateClientToken(accessToken: string) {
    this.client.defaults.headers.common['Authorization'] = accessToken
  }

  updateUserIdInHeader(userId: string) {
    this.client.defaults.headers.common['UserId'] = userId
  }

  async post<TRequest, TResponse>(path: string, payload: TRequest, config?: RequestConfig): Promise<TResponse> {
    try {
      const response = config ? await this.client.post<TResponse>(path, payload, config) : await this.client.post<TResponse>(path, payload)
      return response.data
    } catch (error) {
      console.error(error)
      // handleServiceError(error)
    }
    return {} as TResponse
  }

  async patch<TRequest, TResponse>(path: string, payload: TRequest): Promise<TResponse> {
    try {
      const response = await this.client.patch<TResponse>(path, payload)
      return response.data
    } catch (error) {
      console.error(error)
      // handleServiceError(error)
    }
    return {} as TResponse
  }

  async put<TRequest, TResponse>(path: string, payload: TRequest): Promise<TResponse> {
    try {
      const response = await this.client.put<TResponse>(path, payload)
      return response.data
    } catch (error) {
      console.error(error)
      // handleServiceError(error)
    }
    return {} as TResponse
  }

  async get<TResponse>(path: string): Promise<TResponse> {
    try {
      const response = await this.client.get<TResponse>(path)
      return response.data
    } catch (error) {
      console.error(error)
      // handleServiceError(error)
    }
    return {} as TResponse
  }
}
