import React, { useEffect, useState } from 'react'
import { useSignupContextActions, useSignupContextState } from '../useSignupContext'
import styled from 'styled-components'
import { Outlet, useNavigate } from 'react-router-dom'
import { Button } from '@mui/material'
import CustomStepper from './Stepper'

const msgs: Array<string> = ['This is where the tires meet the road!', 'We will try and make this as painless as possible', 'We are happy you chose us for your business needs!', 'With so much on your daily plate, invoicing should not add to you work load.']

const Signup = () => {
  const navigate = useNavigate()
  const { steps, currenStep } = useSignupContextState()
  const { updateStep } = useSignupContextActions()
  const [disablePrevBtn, setDisablePrevBtn] = useState<boolean>(false)
  const [disableNextBtn, setDisableNextBtn] = useState<boolean>(false)
  const [message, setMessage] = useState<string>('')

  const handleNext = () => {
    determineStepBoundry()
    if (currenStep.id + 1 <= steps.length) {
      updateStep(steps[currenStep.id])
    }
  }

  const handlePrevious = () => {
    determineStepBoundry()
    if (currenStep.id > 1) {
      const val = currenStep.id - 2
      updateStep(steps[val])
    }
  }

  const determineStepBoundry = () => {
    if (currenStep.id + 1 <= steps.length) {
      setDisableNextBtn(false)
    } else {
      setDisableNextBtn(true)
    }
    if (currenStep.id === 1) {
      setDisablePrevBtn(true)
    } else {
      setDisablePrevBtn(false)
    }
  }

  useEffect(() => {
    navigate(currenStep.linkPath)
  }, [currenStep])

  useEffect(() => {
    setMessage(msgs[Math.floor(Math.random() * msgs.length)])
    setInterval(() => {
      setMessage(msgs[Math.floor(Math.random() * msgs.length)])
    }, 8000)
  }, [])

  return (
    <SignUpWrapper>
      <div className='step-container'>
        <div className='stepper-container'>
          <CustomStepper />
        </div>
        <div className='form-content-container'> {<Outlet />}</div>
      </div>
      <div className='step-btn-wrapper'>
        <Button className='nav-btn' variant='contained' onClick={handlePrevious}>
          Previous
        </Button>

        <Button className='nav-btn' variant='contained' onClick={handleNext} disabled={!currenStep.stepComplete}>
          Next
        </Button>
      </div>
      <p className='user-msg'>{message}</p>
    </SignUpWrapper>
  )
}

export default Signup

const SignUpWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 3% auto 0 auto;
  width: 80%;
  max-width: 1200px;
  .step-container {
    margin: 2% 0;
    width: 100%;
    .stepper-container {
      padding: 5% 0;
    }
    .form-content-container {
      height: 600px;
    }
  }

  .step-btn-wrapper {
    display: flex;
    justify-content: space-around;
    width: 70%;
    margin-top: 3%;
    .nav-btn {
      min-width: 175px;
    }
  }
  .user-msg {
    margin-top: 40px;
    font-size: 20px;
    color: rgba(0, 0, 0, 0.6);
    transition: all 0.5s ease-in-out;
    font-style: italic;
  }
`
